import * as React from "react";
import { NavLink } from "react-router-dom";

interface ButtonProps {
  buttonText?: string | null;
  buttonType?: "button" | "submit" | "reset" ;
  className?: string;
  icon?: string;
  children?: React.ReactNode;
  buttonSize?: "xs" | "sm" | "md" | "lg";
  buttonWidth?: "xs" | "sm" | "md" | "lg";
  iconPosition?: "left" | "right";
  buttonStyleOutline?: boolean;
  buttonSoft?: boolean;
  buttonStyleRounded?: boolean;
  disabled?: boolean;
  onClick?: (event?: any) => void;
  href?: string
  buttonStyleType?:
  | "primary"
  | "light"
  | "success"
  | "info"
  | "warning"
  | "danger"
  | "dark"
  | "link"
  | "secondary"
  | "white";
}
const Button = ({
  buttonText = null,
  buttonType = "submit",
  className,
  children,
  icon,
  buttonSize = "md",
  buttonStyleType,
  buttonStyleOutline = false,
  buttonStyleRounded = false,
  iconPosition = "right",
  buttonSoft = false,
  buttonWidth,
  onClick,
  disabled,
  href = undefined
}: ButtonProps): JSX.Element => {
  let size = `btn-${buttonSize}`;
  let styleType = `btn${buttonStyleOutline ? "-outline-" : "-"
    }${buttonStyleType}`;

  let styleRounded = `btn-${buttonStyleRounded ? "rounded" : ""}`;

  return (
    <>
      {href && href.length > 0 ?
        <NavLink
          to={href ?? ''}
          onClick={onClick}
          type={buttonType}
          className={`btn waves-effect waves-light ${size} ${styleType} ${styleRounded} ${className ? className : ""
            } ${buttonSoft ? `btn-soft-${buttonStyleType}` : ""} ${buttonWidth ? `w-${buttonWidth}` : ""
            }`}
        >
          {icon && iconPosition === "left" && (
            <i className={`mr-1 fa ${icon}`}></i>
          )}
          {buttonText && buttonText}
          {children}
          {icon && iconPosition === "right" && (
            <i className={`ml-1 fa ${icon}`}></i>
          )}
        </NavLink>
        :
        <button
          onClick={onClick}
          type={buttonType}
          disabled={disabled}
          className={`btn waves-effect waves-light ${size} ${styleType} ${styleRounded} ${className ? className : ""
            } ${buttonSoft ? `btn-soft-${buttonStyleType}` : ""} ${buttonWidth ? `w-${buttonWidth}` : ""
            }`}
        >
          {icon && iconPosition === "left" && (
            <i className={`mr-1 fa ${icon}`}></i>
          )}
          {buttonText && buttonText}
          {children}
          {icon && iconPosition === "right" && (
            <i className={`ml-1 fa ${icon}`}></i>
          )}
        </button>
      }

    </>
  );
};

export default Button;
