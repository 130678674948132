/* eslint-disable no-empty-pattern */
import { useEffect, useState } from "react";
import axios from "axios";
import { Alert, Card, Input, Button, Select } from "../../Library/Module";
import { toast } from "react-toastify";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Wrapper from "../Wrapper";
import {
    AddVendorService,
    CategoryByCMSType,
    GetCityAreaService,
    GetCityService,
    GetVendorServiceById,
    SubCategoryByCategorySlug,
    UpdateVendorServiceById,
    Vendors,
} from "../../servies/services";
import MediaPopup from "../Media/MediaPopup";
import UserNav from "./UserNav";

type formDataProps = {
    title?: string
    first_name?: string
    last_name?: string
    email_id?: string
    mobile?: string
    city?: string
    area?: string
    address?: string
    about_vendor?: string
    payment_mode?: string
    min_charges?: string
    establish_year?: string
    opening_hours?: string
    pet_category?: string
    service_category?: string
    status?: string
    profile_image?: string
    gallery?: string
    created_by?: string
    _id?: string
};

const statusOption = [
    { text: "Active", id: 1, value: "Active" },
    { text: "Inactive", id: 2, value: "Inactive" },
];

const paymentOption = [
    { text: "Online", id: 1, value: "Online" },
    { text: "Offline", id: 2, value: "Offline" },
    { text: "Online & Offline", id: 3, value: "Online & Offline" },
];

const UpdateVendorDetails = () => {
    const navigate = useNavigate();
    let { pet_category, service_name, type, id } = useParams();

    const [isError, setIsError]: any = useState(false);
    const [isFormSubmitted, setIsFormSubmitted]: any = useState(false);
    const [isLoading, setIsLoading]: any = useState(false);

    const [isValid, setIsValid]: any = useState(true);
    const [formData, setFormData] = useState<formDataProps>();
    const [submitType, setSubmitType] = useState(type);
    const [cityList, setCityList] = useState([]);
    const [areaList, setAreaList] = useState([]);

    const frontendUserData = localStorage.getItem('frontendUserData')
    const userData = frontendUserData && JSON.parse(frontendUserData)

    const [serverError, setServerError]: any = useState(
        "Something went wrong, please try again"
    );

    const onChangeSingleCallback = (data: any) => {
        setFormData((prevState) => ({
            ...prevState,
            ...data,
        }));
        setIsFormSubmitted(false);
    };



    useEffect(() => {

        const data = {
            pet_category: pet_category,
            service_category: service_name
        }

        setFormData((prevState) => ({
            ...prevState,
            ...data,
        }));
    }, [pet_category, service_name])




    useEffect(() => {
        const getData = async () => {
            const APIResponse = await GetCityService();
            if (APIResponse?.data?.isSuccess === true) {
                console.log("APIResponse CategoryByCMSType", APIResponse?.data?.data)
                let updatedList: any = []
                APIResponse?.data?.data?.map((item: any) => {
                    return updatedList.push({ text: item?.title, id: item?._id, value: item?.slug })
                })
                setCityList(updatedList);
            } else {
                toast.error("Something went wrong, please try again");
                setIsError(true);
                setIsLoading(true);
            }
        };
        getData();

    }, [])

    // 

    useEffect(() => {
        if (formData?.city && formData?.city.length > 0) {
            const getData = async () => {
                const APIResponse = await GetCityAreaService(formData?.city);
                if (APIResponse?.data?.isSuccess === true) {
                    console.log("APIResponse CategoryByCMSType", APIResponse?.data?.data)
                    let updatedList: any = []
                    APIResponse?.data?.data?.map((item: any) => {
                        return updatedList.push({ text: item?.title, id: item?._id, value: item?.slug })
                    })
                    setAreaList(updatedList);
                } else {
                    toast.error("Something went wrong, please try again");
                    setIsError(true);
                    setIsLoading(true);
                }
            };
            getData();
        }
    }, [formData?.city])


    useEffect(() => {
        console.log("userData", userData?.email_id)
        setIsLoading(false);
        const getData = async () => {
            const query = `?pet_category=${'dog'}&email_id=${userData?.email_id}&service_category=${'dog-foster-homes'}&city=${'pune'}`
            const APIResponse = await Vendors(query);
            if (APIResponse?.data?.isSuccess === true) {
                console.log("APIResponse?.data", APIResponse?.data)
                setFormData(APIResponse?.data?.data[0]);
                setIsLoading(true);
            } else {
                toast.error("Something went wrong, please try again");
                setIsError(true);
                setIsLoading(true);
            }
        };
        getData();
    }, [userData?.email_id]);

    useEffect(() => {
        setIsLoading(false);

        if (type === "edit" || type === "view") {
            // setSubmitType("edit");
            const getData = async () => {
                const APIResponse = await GetVendorServiceById(id);
                if (APIResponse?.data?.isSuccess === true) {
                    setFormData(APIResponse?.data?.data);
                    setIsLoading(true);
                } else {
                    toast.error("Something went wrong, please try again");
                    setIsError(true);
                    setIsLoading(true);
                }
            };
            getData();
        } else {
            // setSubmitType("edit");
            // setFormData({
            //   title: "",
            //   first_name: "",
            //   last_name: "",
            //   email_id: "",
            //   mobile: "",
            //   address: "",
            //   about_vendor: "",
            //   payment_mode: "",
            //   min_charges: "",
            //   establish_year: "",
            //   opening_hours: "",
            //   pet_category: "",
            //   // service_category: "",
            //   status: "",
            //   profile_image: "",
            //   gallery: "",
            //   created_by: "",
            // });
            setIsLoading(true);
        }
    }, [pet_category, service_name, type, id]);

    const Submit = async (event: any) => {
        setIsFormSubmitted(true);
        if (
            formData?.title !== undefined &&
            formData?.title.length !== 0 &&
            formData?.status !== undefined &&
            formData?.status.length !== 0
        ) {
            setIsValid(true);
            let postData = {
                title: formData?.title,
                first_name: formData?.first_name,
                last_name: formData?.last_name,
                email_id: formData?.email_id,
                mobile: formData?.mobile,
                address: formData?.address,

                city: formData?.city,
                area: formData?.area,

                about_vendor: formData?.about_vendor,
                payment_mode: formData?.payment_mode,
                min_charges: formData?.min_charges,
                establish_year: formData?.establish_year,
                opening_hours: formData?.opening_hours,
                pet_category: formData?.pet_category,
                service_category: formData?.service_category,
                status: formData?.status,
                profile_image: formData?.profile_image,
                gallery: formData?.gallery,
                created_by: formData?.created_by,
                id: formData?._id,
            };
            if (submitType === "add") {
                delete postData.id;
                const APIResponse = await AddVendorService(postData);
                if (APIResponse?.data?.isSuccess === true) {
                    toast.success(APIResponse?.data?.messages);
                    redirectToParent();
                } else {
                    if (APIResponse?.data?.validataion_error) {
                        toast.error(APIResponse?.data?.messages);
                        setServerError(APIResponse?.data?.messages);
                        setIsError(true);
                    } else {
                        toast.error("Something went wrong, please try again");
                        setIsError(true);
                    }
                }
            } else {
                postData.id = formData?._id;
                console.log("postData", postData);
                const APIResponse = await UpdateVendorServiceById(postData);
                if (APIResponse?.data?.isSuccess === true) {
                    toast.success(APIResponse?.data?.messages);
                    redirectToParent();
                } else {
                    if (APIResponse?.data?.validataion_error) {
                        toast.error(APIResponse?.data?.messages);
                        setServerError(APIResponse?.data?.messages);
                        setIsError(true);
                    } else {
                        toast.error("Something went wrong, please try again");
                        setIsError(true);
                    }
                }
            }
        } else {
            setIsValid(false);
            toast.error("Please enter required fields");
        }
    };

    const redirectToParent = () => {
        navigate("/my-pawzwhiskers-space/update-dog-foster-homes");
    };

    const breadcrumbList = [
        {
            title: "Dashboard",
            link: "/dashboard",
        },
        {
            title: "City Management",
            link: "/pets-management/view-pets",
        },
        {
            title: "Add City",
            link: "/pets-management/view-pets",
        },
    ];

    return (
        <Wrapper pageTitle="Vendor Management" breadcrumbList={breadcrumbList}>
            <UserNav></UserNav>
            <div className="mySpace">
                <div className="container">
                    <div className="row">
                        <Card title="Update Dog Foster Homes">
                            <p className="text-left mb-4">
                                You can update the Dog Foster Homes details in this module.
                            </p>
                            <div className="">
                                {isError && (
                                    <Alert AlertStyleType="danger" AlertText={serverError}></Alert>
                                )}
                                {!isValid && (
                                    <Alert
                                        AlertStyleType="danger"
                                        AlertText={`Please enter required fields`}
                                    ></Alert>
                                )}
                                {isLoading && (
                                    <div className="row">
                                        <div className="col-md-8">
                                            <div className="row">
                                                <Input
                                                    inputName="title"
                                                    inputType="text"
                                                    labelName={"Profile Name"}
                                                    placeholder="Enter Profile Name"
                                                    required={true}
                                                    onChangeSingleCallback={onChangeSingleCallback}
                                                    col="12"
                                                    isFormSubmitted={isFormSubmitted}
                                                    disabled={submitType === "view"}
                                                    value={
                                                        type === "add" && formData?.title?.length === 0
                                                            ? ""
                                                            : formData?.title
                                                    }
                                                />
                                                <Input
                                                    inputName="first_name"
                                                    inputType="text"
                                                    labelName={"First Name"}
                                                    placeholder="Enter First Name"
                                                    required={true}
                                                    onChangeSingleCallback={onChangeSingleCallback}
                                                    col="6"
                                                    isFormSubmitted={isFormSubmitted}
                                                    disabled={submitType === "view"}
                                                    value={
                                                        type === "add" && formData?.first_name?.length === 0
                                                            ? ""
                                                            : formData?.first_name
                                                    }
                                                />
                                                <Input
                                                    inputName="last_name"
                                                    inputType="text"
                                                    labelName={"Last Name"}
                                                    placeholder="Enter Last Name"
                                                    required={true}
                                                    onChangeSingleCallback={onChangeSingleCallback}
                                                    col="6"
                                                    isFormSubmitted={isFormSubmitted}
                                                    disabled={submitType === "view"}
                                                    value={
                                                        type === "add" && formData?.last_name?.length === 0
                                                            ? ""
                                                            : formData?.last_name
                                                    }
                                                />

                                                <Input
                                                    inputName="email_id"
                                                    inputType="text"
                                                    labelName={"Email Id"}
                                                    placeholder="Email Id"
                                                    required={true}
                                                    onChangeSingleCallback={onChangeSingleCallback}
                                                    col="6"
                                                    isFormSubmitted={isFormSubmitted}
                                                    disabled={true}
                                                    value={
                                                        type === "add" && formData?.email_id?.length === 0
                                                            ? ""
                                                            : formData?.email_id
                                                    }
                                                />

                                                <Input
                                                    inputName="mobile"
                                                    inputType="text"
                                                    labelName={"Mobile"}
                                                    placeholder="Mobile"
                                                    required={true}
                                                    onChangeSingleCallback={onChangeSingleCallback}
                                                    col="6"
                                                    isFormSubmitted={isFormSubmitted}
                                                    disabled={submitType === "view"}
                                                    value={
                                                        type === "add" && formData?.mobile?.length === 0
                                                            ? ""
                                                            : formData?.mobile
                                                    }
                                                />

                                                <Input
                                                    inputName="address"
                                                    inputType="text"
                                                    labelName={"Address"}
                                                    placeholder="Enter address"
                                                    onChangeSingleCallback={onChangeSingleCallback}
                                                    col="12"
                                                    isFormSubmitted={isFormSubmitted}
                                                    value={formData?.address}
                                                    disabled={submitType === "view"}
                                                />

                                                <Select
                                                    inputName="city"
                                                    labelName="City"
                                                    options={cityList}
                                                    onChangeSingleCallback={onChangeSingleCallback}
                                                    selectedItem={cityList?.find(
                                                        (item: any) => item?.value === formData?.city
                                                    )}
                                                    required={true}
                                                    placeholder="Select city"
                                                    search_option={false}
                                                    disabled={submitType === "view"}
                                                    col="6"
                                                ></Select>
                                                <Select
                                                    inputName="area"
                                                    labelName="Area"
                                                    options={areaList}
                                                    onChangeSingleCallback={onChangeSingleCallback}
                                                    selectedItem={areaList?.find(
                                                        (item: any) => item?.value === formData?.area
                                                    )}
                                                    required={true}
                                                    placeholder="Select area"
                                                    search_option={false}
                                                    disabled={submitType === "view"}
                                                    col="6"
                                                ></Select>


                                                <Input
                                                    inputName="about_vendor"
                                                    inputType="text"
                                                    labelName={"About Vendor"}
                                                    placeholder="About Vendor"
                                                    onChangeSingleCallback={onChangeSingleCallback}
                                                    col="12"
                                                    isFormSubmitted={isFormSubmitted}
                                                    value={formData?.about_vendor}
                                                    disabled={submitType === "view"}
                                                />
                                                <Select
                                                    inputName="payment_mode"
                                                    labelName="Payment Mode"
                                                    options={paymentOption}
                                                    onChangeSingleCallback={onChangeSingleCallback}
                                                    selectedItem={paymentOption.find(
                                                        (item) => item.value === formData?.payment_mode
                                                    )}
                                                    required={true}
                                                    placeholder="Select Payment Mode"
                                                    search_option={false}
                                                    disabled={submitType === "view"}
                                                    col="6"
                                                ></Select>

                                                <Input
                                                    inputName="min_charges"
                                                    inputType="text"
                                                    labelName={"Min Charges"}
                                                    placeholder="Min Charges"
                                                    onChangeSingleCallback={onChangeSingleCallback}
                                                    col="6"
                                                    isFormSubmitted={isFormSubmitted}
                                                    value={formData?.min_charges}
                                                    disabled={submitType === "view"}
                                                />

                                                <Input
                                                    inputName="establish_year"
                                                    inputType="text"
                                                    labelName={"Establish Year"}
                                                    placeholder="Establish Year"
                                                    onChangeSingleCallback={onChangeSingleCallback}
                                                    col="12"
                                                    isFormSubmitted={isFormSubmitted}
                                                    value={formData?.establish_year}
                                                    disabled={submitType === "view"}
                                                />

                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="row align-content-end justify-content-end">


                                                <Input
                                                    inputName="opening_hours"
                                                    inputType="text"
                                                    labelName="Opening Hours"
                                                    placeholder="Opening Hours"
                                                    onChangeSingleCallback={onChangeSingleCallback}
                                                    col="12"
                                                    isFormSubmitted={isFormSubmitted}
                                                    value={formData?.opening_hours}
                                                    disabled={submitType === "view"}
                                                />

                                                <Select
                                                    inputName="status"
                                                    labelName="Status"
                                                    options={statusOption}
                                                    onChangeSingleCallback={onChangeSingleCallback}
                                                    selectedItem={statusOption.find(
                                                        (item) => item.value === formData?.status
                                                    )}
                                                    required={true}
                                                    placeholder="Select Status"
                                                    search_option={false}
                                                    disabled={submitType === "view"}
                                                ></Select>
                                                <p><strong>Profile Image/Logo</strong></p>
                                                <MediaPopup
                                                    onSelectChange={onChangeSingleCallback}
                                                    disabled={submitType === "view"}
                                                    title={
                                                        formData?.profile_image !== null && formData?.profile_image !== "null"
                                                            ? "Change Image"
                                                            : "Select Image"
                                                    }
                                                    inputName="profile_image"
                                                />

                                                {formData?.profile_image && formData?.profile_image.length > 0 &&
                                                    <div className="imagePlaceholder"><img src={process.env.react_app_base_url + `/${formData?.profile_image}`} alt="" title="" /></div>}

                                                <p><strong>Gallery</strong></p>
                                                <MediaPopup
                                                    onSelectChange={onChangeSingleCallback}
                                                    disabled={submitType === "view"}
                                                    title={
                                                        formData?.gallery !== null && formData?.gallery !== "null"
                                                            ? "Change Image"
                                                            : "Select Image"
                                                    }
                                                    inputName="gallery"
                                                />

                                                {formData?.gallery && formData?.gallery.length > 0 &&
                                                    <div className="imagePlaceholder"><img src={process.env.react_app_base_url + `/${formData?.gallery}`} alt="" title="" /></div>}
                                                <div className="col-sm-6 text-right">
                                                    <Button
                                                        onClick={() => redirectToParent()}
                                                        buttonText={"Cancel"}
                                                        buttonStyleType={"primary"}
                                                    />
                                                </div>
                                                <div className="col-sm-6 text-right">
                                                    <Button
                                                        onClick={() => Submit("")}
                                                        disabled={submitType === "view"}
                                                        buttonText={
                                                            submitType === "add" || submitType === "view"
                                                                ? "Submit"
                                                                : "Update"
                                                        }
                                                        buttonStyleType={"primary"}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
};
export default UpdateVendorDetails;
