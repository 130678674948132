/* eslint-disable no-empty-pattern */
import {

    GenderOption,

    dog_temperament,

} from "../../components/utility";
import { useEffect, useState } from "react";
import { Button, Input, Select } from "../../Library/Module";
import DatePickerComp from "../../Library/DatePicker/DatePicker";
import { localStorageParse } from "../../Context/ThemeContext";

type formDataProps = {
    [key: string]: string;
};

type Props = {
    showResult: any
    pet: any
    category: any
    userLogged: any
    area: any
    scollToRef?: any
    handleClick?: any
}
const ServicesDetails = ({
    showResult = false,
    pet,
    category,
    userLogged,
    area,
    scollToRef,
    handleClick
}: Props): JSX.Element => {
    const initialData = localStorageParse(pet + "_" + category)
    const [formData, setFormData] = useState<any>(initialData && JSON.parse(initialData));

    const [isValid, setIsValid] = useState(false)
    const onChangeSingleCallback = (data: any) => {
        setFormData((prevState: any) => ({
            ...prevState,
            ...data,
        }));
        showResult(false);
    };
    const Submit = () => {
        showResult(true);
        localStorage.setItem(pet + "_" + category, JSON.stringify(formData))
        if (scollToRef.current) {
            scollToRef.current.scrollIntoView()
        }
        handleClick?.()
    };
    useEffect(() => {
        if (formData?.dog_bread && formData?.dog_bread?.length > 0 &&
            formData?.gender && formData?.gender?.length > 0 &&
            formData?.age && formData?.age?.length > 0 &&
            formData?.dog_temperament && formData?.dog_temperament?.length > 0 &&
            formData?.food_preference && formData?.food_preference?.length > 0 &&
            formData?.health_allergies && formData?.health_allergies?.length > 0 
        ) {
            if (userLogged === true) {
                setIsValid(true)
            } else {
                setIsValid(true)
            }
        } else {
            setIsValid(false)
        }

    }, [formData, userLogged])
    return (
        <>
            <div className="row">
                <Input
                    inputName="dog_bread"
                    inputType="text"
                    labelName={"Dog Breed"}
                    placeholder="Enter Dog Breed"
                    required={true}
                    onChangeSingleCallback={onChangeSingleCallback}
                    col="3"
                    value={formData?.dog_bread}
                />
                <Select
                    inputName="gender"
                    labelName="Gender"
                    options={GenderOption ?? []}
                    onChangeSingleCallback={onChangeSingleCallback}
                    required={true}
                    placeholder="Select Gender"
                    search_option={false}
                    col="3"
                    selectedItem={GenderOption?.find((item: any) => {
                        return item.value === formData?.gender;
                    })}
                ></Select>
                <Input
                    inputName="age"
                    inputType="number"
                    labelName={"Age"}
                    placeholder="Enter Age"
                    required={true}
                    onChangeSingleCallback={onChangeSingleCallback}
                    col="3"
                    value={formData?.age}
                />
                <Select
                    inputName="dog_temperament"
                    labelName="Dog Temperament"
                    options={dog_temperament ?? []}
                    onChangeSingleCallback={onChangeSingleCallback}
                    required={true}
                    placeholder="Select Dog Temperament"
                    search_option={false}
                    col="3"
                    selectedItem={dog_temperament?.find((item: any) => {
                        return item.value === formData?.dog_temperament;
                    })}
                ></Select>
                <Input
                    inputName="food_preference"
                    inputType="text"
                    labelName={"Food Preference"}
                    placeholder="Enter Food Preference"
                    required={true}
                    onChangeSingleCallback={onChangeSingleCallback}
                    col="3"
                    value={formData?.food_preference}
                />
                <Input
                    inputName="health_allergies"
                    inputType="text"
                    labelName={"Health and Allergies"}
                    placeholder="Enter Health and Allergies"
                    required={true}
                    onChangeSingleCallback={onChangeSingleCallback}
                    col="3"
                    value={formData?.health_allergies}
                />
                <DatePickerComp
                    inputName="appointment_date"
                    labelName={"Appointment Date"}
                    placeholder="Enter Appointment Date"
                    required={true}
                    onChangeSingleCallback={onChangeSingleCallback}
                    col="3"></DatePickerComp>
                <Select
                    inputName="area"
                    labelName="Area"
                    options={area ?? []}
                    onChangeSingleCallback={onChangeSingleCallback}
                    required={true}
                    placeholder="Select Area"
                    search_option={false}
                    col="3"
                    selectedItem={area?.find((item: any) => {
                        return item.value === formData?.area;
                    })}
                ></Select>
            </div>
            <div className="row align-items-center">
                <div className="col-sm-2">
                    <Button
                        onClick={() => Submit()}
                        buttonText={"Search"}
                        buttonStyleType={"primary"}
                        buttonWidth="sm"
                        disabled={!isValid}
                    />
                </div>
            </div>
        </>
    )
};
export default ServicesDetails;