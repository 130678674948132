/* eslint-disable no-empty-pattern */
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../Library/Loader/Loader";
import { Button, Input } from "../../Library/Module";
import { CMSBySlug, EnquiryDetails } from "../../servies/services";
import Wrapper from "../Wrapper";

type formDataProps = {
  email_id: string;
  full_name: string;
  business_name?: string;
  phone_number?: string;
  commnet?: string;
};

const ContactUs = () => {
  let { id, type, action } = useParams();
  const [isLoading, setIsLoading]: any = useState(false);
  const [data, setData]: any = useState(false);
  const [isError, setIsError] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const [isFormSubmitted, setIsFormSubmitted]: any = useState(false);
  const [formData, setFormData] = useState<formDataProps>({
    full_name: '',
    business_name: '',
    email_id: '',
    phone_number: '',
    commnet: ""
  });

  const onChangeSingleCallback = (data: any) => {
    setFormData((prevState) => ({
      ...prevState,
      ...data,
    }));
    setIsFormSubmitted(false);
  };

  useEffect(() => {
    setIsLoading(false);
    const getData = async () => {
      const APIResponse = await CMSBySlug('contact-us');
      if (APIResponse?.data?.isSuccess === true) {
        setData(APIResponse?.data?.data);
        setIsLoading(true);
      } else {
        toast.error("Something went wrong, please try again");
        setIsError(true);
        setIsLoading(true);
      }
    };
    getData();
  }, []);

  useEffect(() => {
    if (formData?.full_name && formData?.full_name?.length > 0 &&
      formData?.email_id && formData?.email_id?.length > 0) {
      setDisabled(false)
    }

  }, [formData])

  const Submit = async () => {
    const APIResponse = await EnquiryDetails(formData);
    if (APIResponse?.data?.isSuccess === true) {
      toast.success('Your enquiry has been submitted successfully.', {
        position: toast.POSITION.TOP_CENTER
      });

      setFormData({
        full_name: '',
        business_name: '',
        email_id: '',
        phone_number: '',
        commnet: ""
      });
    } else {
      toast.error("Something went wrong, please try again");
      setIsError(true);
      setIsLoading(true);
    }
  }

  return (
    <Wrapper>
      {isLoading &&
        <>
          <div className="pageTitle">
            <div className="container">
              <div className="row align-items-center">
                <div className="col">
                  <div className="innerPageTitle">
                    <h1>{data?.title}</h1>
                    <div dangerouslySetInnerHTML={{ __html: data?.short_description }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div className="aboutUs">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-sm-12 col-md-12">
                  {/* <div dangerouslySetInnerHTML={{ __html: data?.description }} /> */}
                  <div className="contact-form">
                    <h3>Contact Us</h3>
                    <p>Let's get this conversation started, Tell us bit about yourself and we'll get in touch as soon as we can.</p>
                    <Input
                      inputName="full_name"
                      inputType="text"
                      labelName={"Full Name"}
                      placeholder="Enter Full Name"
                      required={true}
                      onChangeSingleCallback={onChangeSingleCallback}
                      col="12"
                      isFormSubmitted={isFormSubmitted}
                      value={formData?.full_name}
                    />

                    <Input
                      inputName="business_name"
                      inputType="text"
                      labelName={"Business Name"}
                      placeholder="Enter Business Name"
                      required={false}
                      onChangeSingleCallback={onChangeSingleCallback}
                      col="12"
                      isFormSubmitted={isFormSubmitted}
                      value={formData?.business_name}
                    />

                    <Input
                      inputName="email_id"
                      inputType="email"
                      labelName={"Email ID"}
                      placeholder="Enter Email ID"
                      required={true}
                      onChangeSingleCallback={onChangeSingleCallback}
                      col="12"
                      isFormSubmitted={isFormSubmitted}
                      value={formData?.email_id}
                    />

                    <Input
                      inputName="phone_number"
                      inputType="text"
                      labelName={"Phone Number"}
                      placeholder="Enter Phone Number"
                      required={false}
                      onChangeSingleCallback={onChangeSingleCallback}
                      col="12"
                      isFormSubmitted={isFormSubmitted}
                      value={formData?.phone_number}
                    />

                    <Input
                      inputName="commnet"
                      inputType="textarea"
                      labelName={"Comment"}
                      placeholder="Enter Comment"
                      required={false}
                      onChangeSingleCallback={onChangeSingleCallback}
                      col="12"
                      isFormSubmitted={isFormSubmitted}
                      value={formData?.commnet}
                    />
                    <Button
                      onClick={() => Submit()}
                      buttonText={"Submit"}
                      buttonStyleType={"primary"}
                      disabled={disabled}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      }
      {!isLoading && <Loader loaderType={'pageLoader'}></Loader>}
    </Wrapper>
  );
};
export default ContactUs;
