import { Tooltip } from "react-tippy";
import "react-tippy/dist/tippy.css";

interface CustomTooltipProps {
  title?: string;
  position?: "bottom" | "top";
  trigger?: "mouseenter" | "focus" | "click";
  children?: any;
  content?: any;
  interactive?: boolean;
  hideOnClick?: boolean;
  width?: string;
  disabled?: boolean;
}
const CustomTooltip = ({
  title,
  position = "bottom",
  trigger = "click",
  children,
  content,
  interactive = true,
  hideOnClick = true,
  width = "200px",
  disabled = false
}: CustomTooltipProps): any => {
  return (
    <>
    </>
  );
};

export default CustomTooltip;
