import { useContext, useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import Logo from "../images/logo.png";
import { Button, Select } from "../Library/Module";
import { ThemeContext } from "../Context/ThemeProvider";
import { localStorageParse } from "../Context/ThemeContext";

import CatIcon from '../images/Icon/Cat.png'
import DogIcon from '../images/Icon/Dog.png'

export interface formDataProps {
  pet?: string;
  city?: string;
  area?: string;
}

const CityOption = [
  {
    id: 0,
    text: "Pune",
    value: "pune",
  },
];
type AlertProps = {
  customPostTypeCat: any
  cityList: any
  isCatLoading: any
  isCityLoading: any
}
const Header = ({
  customPostTypeCat,
  cityList,
  isCatLoading,
  isCityLoading
}: AlertProps): JSX.Element => {

  const frontendUserData = localStorage.getItem("frontendUserData")
  const frontendUser = frontendUserData && JSON.parse(frontendUserData)
  const frontendUserProfile = localStorage.getItem("frontendUserProfile")
  const frontendUserDetails = frontendUserProfile && JSON.parse(frontendUserProfile)

  let navigate = useNavigate();
  let { categry, serviceElement } = useParams();
  console.log("categry/:serviceElement", categry, serviceElement)
  const location = useLocation();
  console.log("categry/:serviceElement", location.pathname)


  const Login = () => {
    navigate("/login");
  };

  const Register = () => {
    navigate("/register");
  };

  const Dashboard = () => {
    navigate("/my-pawzwhiskers-space/dashboard");
  };

  const Logout = () => {
    navigate("/");
    localStorage.removeItem("frontendUserData")
    localStorage.removeItem("frontendUserProfile")
  }

  const [formData, setFormData] = useState<formDataProps>({ "city": 'pune' });
  const onChangeSingleCallback = (data: any) => {
    console.log("onChangeSingleCallback", data)
    setFormData((prevState) => ({
      ...prevState,
      ...data,
    }));
    if (data) {
      console.log("pet", data)
      if (location.pathname?.includes('service-details')) {
        window.location.assign('/services');
      } else {
        window.location.reload();
      }

    }
  };

  useEffect(() => {
    const pet_prefernce = localStorageParse('pet_prefernce')
    if (pet_prefernce) {
      console.log("pet_prefernce", JSON.parse(pet_prefernce)?.pet)
      const parse = JSON.parse(pet_prefernce)
      setFormData((prevState) => ({
        ...prevState,
        ...parse,
      }));
    }
  }, []);


  useEffect(() => {
    if (formData?.pet) {
      let abc = { 'pet': formData?.pet }
      localStorage.setItem('pet_prefernce', JSON.stringify(abc))
    }
  }, [formData?.pet]);

  const [show, setShow] = useState(false);
  const MenuButton = () => {
    setShow(!show);
  };


  console.log("formData", formData)


  useEffect(() => {
    const city_prefernce = localStorageParse('city_prefernce')
    if (city_prefernce) {
      console.log("city_prefernce", JSON.parse(city_prefernce)?.city)
      const parse = JSON.parse(city_prefernce)
      setFormData((prevState) => ({
        ...prevState,
        ...parse,
      }));
    }
  }, []);


  useEffect(() => {
    if (formData?.city) {
      let abc = { 'city': formData?.city }
      localStorage.setItem('city_prefernce', JSON.stringify(abc))
    }
  }, [formData?.city]);



  return (
    <>
      <div className="header" id="mobileHeader">
        <div className="container">
          <div className="row">
            <div className="col-sm-2 com-md-2 ">
              <div className="headerLogo">
                <NavLink to={"/"}>
                  <img src={Logo} title="" alt="" />
                </NavLink>
              </div>
            </div>
            <div className="col-sm-10 com-md-10">
              <div className="topSection ">
                <div className="row align-items-center">
                  {/* <div className="col newsFlash float-start">
                    <strong> Latest Update : </strong> We connect pet_category lovers
                    with professionals Home
                  </div> */}
                  <div className="col">
                    <div className="navSelection float-end">
                      <div className="inputGroup float-start">


                        <div className="flex">
                          <label>Select Pet </label>
                          <div className="listHeader">
                            {customPostTypeCat?.map((item: any) => {
                              return (
                                <div className={formData?.pet === item?.value ? "active" : "inactive"} onClick={() => onChangeSingleCallback({ pet: item?.value })}>
                                  {item?.text === "Cat" ? <img src={CatIcon} alt="" title="" /> : <img src={DogIcon} alt="" title="" />}
                                </div>
                              )
                            })}
                          </div>
                        </div>

                        {/* <Select
                          inputName="pet"
                          labelName="Pet"
                          options={customPostTypeCat ?? []}
                          onChangeSingleCallback={onChangeSingleCallback}
                          required={true}
                          placeholder="Select Pet"
                          search_option={false}
                          col="12"
                          selectedItem={customPostTypeCat?.find((item: any) => {
                            return item.value === formData?.pet;
                          })}
                        ></Select> */}
                      </div>
                      <div className="inputGroup float-start">
                        <Select
                          inputName="city"
                          labelName="City"
                          options={cityList}
                          onChangeSingleCallback={onChangeSingleCallback}
                          required={true}
                          placeholder="Select City"
                          search_option={false}
                          col="12"
                          selectedItem={cityList.find((item: any) => {
                            return item.value === formData?.city;
                          })}
                        ></Select>
                      </div>

                      {/* <div className="inputGroup float-start">
                        <Select
                          inputName="area"
                          labelName="Area"
                          options={areaList}
                          onChangeSingleCallback={onChangeSingleCallback}
                          required={true}
                          placeholder="Select Area"
                          search_option={false}
                          col="12"
                          selectedItem={areaList.find((item: any) => {
                            return item.value === formData?.area;
                          })}
                        ></Select>
                      </div> */}
                    </div>
                  </div>
                  <div className="clearfix"></div>
                </div>
              </div>
              <div className="topNav float-start">
                <ul>
                  <li>
                    <NavLink to={"/"}> Home </NavLink>
                  </li>
                  <li>
                    <NavLink to={"/about-us"}> About Us </NavLink>
                  </li>
                  <li>
                    <NavLink to={"/services"}> Services </NavLink>
                  </li>
                  <li>
                    <NavLink to={"/contact-us"}> Contact Us </NavLink>
                  </li>
                  <li>
                    <NavLink to={"/pet-friendly-places"}> Pet Friendly Places </NavLink>
                  </li>

                </ul>
              </div>
              <div className="topLoginNav float-end">
                {frontendUser?.token ?
                  <>
                    Welcome {frontendUserDetails?.first_name} {frontendUserDetails?.last_name}
                    <Button
                      onClick={() => Dashboard()}
                      buttonStyleRounded
                      buttonStyleType="primary"
                    >
                      Dashboard
                    </Button>
                    <Button
                      onClick={() => Logout()}
                      buttonStyleRounded
                      buttonStyleType="danger"
                    >
                      Logout
                    </Button>
                  </>
                  :
                  <>
                    <Button
                      onClick={() => Login()}
                      buttonStyleRounded
                      buttonStyleType="primary"
                    >
                      Login
                    </Button>
                    <Button
                      onClick={() => Register()}
                      buttonStyleRounded
                      buttonStyleType="secondary"
                    >
                      Register
                    </Button>
                  </>}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="headerMobile">
        <div className="container">
          <div className="row align-items-center ">
            <div className="col-6">
              <div className="mobileLogo">
                <NavLink to={"/"}>
                  <img src={Logo} title="" alt="" />
                </NavLink>
              </div>
            </div>
            <div className="col-6 text-right">
              <Button
                onClick={() => MenuButton()}
                buttonSize="lg"
                buttonStyleOutline
                buttonStyleType="primary"
                className="MobileMenuButton"
              >
                <span className="line"> </span>
                <span className="line"> </span>
                <span className="line"> </span>
              </Button>
            </div>
          </div>

          {show && (
            <>
              <div className="showMenuAbs" onClick={() => MenuButton()}></div>
              <div className="showMenu">
                <div className="row align-items-center ">
                  <div className="topSection ">
                    <div className="row align-items-center">
                      <div className="col-12 newsFlash">
                        <strong> Latest Update : </strong> We connect pet_category lovers
                        with professionals Home
                      </div>
                      <div className="row">
                        <Select
                          inputName="pet"
                          labelName="Pet"
                          options={customPostTypeCat ?? []}
                          onChangeSingleCallback={onChangeSingleCallback}
                          required={true}
                          placeholder="Select Pet"
                          search_option={false}
                          col="12"
                          selectedItem={customPostTypeCat.find((item: any) => {
                            return item.value === formData?.pet;
                          })}
                        ></Select>

                        <Select
                          inputName="city"
                          labelName="City"
                          options={cityList}
                          onChangeSingleCallback={onChangeSingleCallback}
                          required={true}
                          placeholder="Select City"
                          search_option={false}
                          col="12"
                          selectedItem={cityList.find((item: any) => {
                            return item.value === formData?.city;
                          })}
                        ></Select>
                      </div>
                      <div className="clearfix"></div>
                    </div>
                  </div>
                  <div className="topNav">
                    <ul>
                      <li>
                        <NavLink to={"/"}> Home </NavLink>
                      </li>
                      <li>
                        <NavLink to={"/about-us"}> About Us </NavLink>
                      </li>
                      <li>
                        <NavLink to={"/services"}> Services </NavLink>
                      </li>
                      <li>
                        <NavLink to={"/contact-us"}> Contact Us </NavLink>
                      </li>
                    </ul>
                  </div>
                  <div className="topLoginNav float-end">
                    {frontendUser?.token ?
                      <>
                        Welcome {frontendUserDetails?.first_name} {frontendUserDetails?.last_name}
                        <Button
                          onClick={() => Dashboard()}
                          buttonStyleRounded
                          buttonStyleType="primary"
                        >
                          Dashboard
                        </Button>
                        <Button
                          onClick={() => Logout()}
                          buttonStyleRounded
                          buttonStyleType="danger"
                        >
                          Logout
                        </Button>
                      </>
                      :
                      <>
                        <Button
                          onClick={() => Login()}
                          buttonStyleRounded
                          buttonStyleType="primary"
                        >
                          Login
                        </Button>
                        <Button
                          onClick={() => Register()}
                          buttonStyleRounded
                          buttonStyleType="secondary"
                        >
                          Register
                        </Button>
                      </>}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

    </>
  );
};
export default Header;
