import React, { useEffect, useState, useMemo } from "react";
import CustomTooltip from "../Tooltip/Tippy";
import Button from "../Button/Button";
import { DownloadExcel, donwloadOptions } from "../Utility/Utility";

interface TableDownloadProps {
  data: any;
  tableTitle: any;
  sortingData: any;
  disabled?: boolean;
}
const TableDownload = ({
  data,
  tableTitle,
  sortingData,
  disabled = false,
}: TableDownloadProps): JSX.Element => {
  return (
    <div className="ml-3">
      <div className="dropdown">
        <CustomTooltip
          title={"title"}
          position={"bottom"}
          disabled={disabled}
          trigger={"click"}
          content={
            <ul className="dropdownOption">
              {data[0] &&
                donwloadOptions.map((item, i) => (
                  <li className="changeCase" style={{ padding: "0px" }}>
                    <Button
                      icon="fa-download"
                      iconPosition="left"
                      onClick={(e) =>
                        DownloadExcel(data, tableTitle, item.type, e)
                      }
                      buttonText={item.text + "--" + data.length}
                    ></Button>
                  </li>
                ))}

              {sortingData[0] &&
                sortingData.length !== data.length &&
                donwloadOptions.map((item, i) => (
                  <li className="changeCase" style={{ padding: "0px" }}>
                    <Button
                      icon="fa-download"
                      iconPosition="left"
                      onClick={(e) =>
                        DownloadExcel(sortingData, tableTitle, item.type, e)
                      }
                      buttonText={
                        "Filtered " + item.text + " --" + sortingData.length
                      }
                    ></Button>
                  </li>
                ))}
            </ul>
          }
        >
          <button
            disabled={disabled}
            className="btn btn-outline-primary dropdown-toggle"
          >
            <i className="fa fa-download"></i>
          </button>
        </CustomTooltip>
      </div>
    </div>
  );
};

export default TableDownload;
