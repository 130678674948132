/* eslint-disable no-empty-pattern */
import { useNavigate } from "react-router-dom";
import { Button, Card } from "../../Library/Module";
import Wrapper from "../Wrapper";
import UserNav, { listData } from "./UserNav";

const Dashboard = () => {
  const navigate = useNavigate();
  const breadcrumbList = [
    {
      title: "Dashboard",
      link: "/dashboard",
    },
  ];

  return (
    <>
      <Wrapper pageTitle="Dashboard">
        <UserNav></UserNav>
        <div className="mySpace">
          <div className="container">
            <div className="row">
              {listData?.map((item: any) => {
                return (
                  <>
                    {['dashboard', 'logout'].includes(item?.slug) ? '' :
                      <div className="col-md-4 mb-3">
                        <Card title={item?.title}>
                          <p>{item?.description}</p>
                          <Button buttonStyleOutline buttonStyleType="primary" onClick={() => navigate(item?.url)}>{item?.title}</Button>
                        </Card></div>
                    }
                  </>)
              })}


            </div>
          </div>
        </div>
      </Wrapper>
    </>
  );
};
export default Dashboard;
