import { useState } from "react";
import { NavLink } from "react-router-dom";
import Brand from "./Brand";
import Slider1 from "../images/slider1.jpg";
import Slider1Cat from "../images/slider1Cat.png";
import slider1Cat1 from "../images/slider1Cat2.png";


import { Button } from "../Library/Module";
import { localStorageParse } from "../Context/ThemeContext";

const Header = () => {
  const pet_prefernce = localStorageParse('pet_prefernce')
  const parse = pet_prefernce && JSON.parse(pet_prefernce)
  console.log("parse", parse?.pet)
  return (

    <div className="slider">
      <div className="item">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-sm-12 col-md-6">
              <div className="inner">
              <h2>
                Take <strong>Amazing Care</strong> of your pets!
              </h2>
              <p>We connect pet lovers with professionals Sign Up Today !</p>
              <Button buttonSize="lg" href="/register" buttonStyleType="primary">Sign Up Today !</Button>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 position-relative">
              <div className="placeholder"></div>
              <img src={parse?.pet === 'dog' ? Slider1 : slider1Cat1} title="" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Header;
