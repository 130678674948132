/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import EnquiryRow from "./EnquiryRow";
import { Alert, Button, Card, CustomTable, Input } from "../../Library/Module";
import Wrapper from "../Wrapper";
import UserNav from "./UserNav";

type formDataProps = {
    confirm_password: string;
    password: string;
};

const MyEnquiries = () => {
    const [responseData, setResponseData]: any = useState([]);
    const [loading, setLoading] = useState(false);

    const [formData, setFormData] = useState<formDataProps>();
    const [isFormSubmitted, setIsFormSubmitted]: any = useState(false);
    const [isError, setIsError]: any = useState(false);
    const [isValid, setIsValid]: any = useState(true);

    const frontendUserData = localStorage.getItem('frontendUserData')
    const userData = frontendUserData && JSON.parse(frontendUserData)

    const onChangeSingleCallback = (data: any) => {
        setFormData((prevState) => ({
            ...prevState,
            ...data,
        }));
        setIsFormSubmitted(false);
    };

    const Submit = async (event: any) => {
        setIsFormSubmitted(true);
        if (
            formData?.confirm_password !== undefined &&
            formData?.confirm_password.length !== 0 &&
            formData?.password !== undefined &&
            formData?.password.length !== 0
        ) {
            setIsValid(true);
            const postData = {
                user_token: userData?.token,
                password: formData?.password,
            };
            await axios
                .post(`https://pawzwhiskers.com/nodeapi/api/v1/user/changepassword`, postData)
                .then((response) => {
                    console.log("response", response);
                    toast.success(response.data.messages);
                })
                .catch(function (error) {
                    toast.error("Something went wrong, please try again");
                    setIsError(true);
                    console.log(error);
                });
        } else {
            setIsValid(false);
            toast.error("Please enter required fields");
        }
    };

    return (
        <Wrapper>
            <UserNav></UserNav>
            <div className="mySpace">
                <div className="container">
                    <div className="row">
                        <Card title={'Change Password'}>
                            <p className="mb-4">
                                You can change the password in this section.
                            </p>
                            {isError && (
                                <Alert
                                    AlertStyleType="danger"
                                    AlertText={`some error msg here`}
                                ></Alert>
                            )}
                            {!isValid && (
                                <Alert
                                    AlertStyleType="danger"
                                    AlertText={`Please enter required fields`}
                                ></Alert>
                            )}
                            <div className="row">
                                <Input
                                    inputName="password"
                                    inputType="password"
                                    labelName={"Password"}
                                    placeholder="Enter password"
                                    required={true}
                                    onChangeSingleCallback={onChangeSingleCallback}
                                    col="12"
                                    isFormSubmitted={isFormSubmitted}
                                />

                                <Input
                                    inputName="confirm_password"
                                    inputType="password"
                                    labelName={"Confirm password"}
                                    placeholder="Enter confirm  password"
                                    required={true}
                                    onChangeSingleCallback={onChangeSingleCallback}
                                    col="12"
                                    isFormSubmitted={isFormSubmitted}
                                />
                            </div>
                            <div className="row align-content-end justify-content-end">
                                <div className="col-sm-6 text-left">

                                </div>
                                <div className="col-sm-6 text-right">
                                    <Button
                                        onClick={() => Submit("")}
                                        buttonText={"Change Password"}
                                        buttonStyleType={"primary"}
                                    />
                                </div>
                            </div>
                        </Card>
                    </div></div></div>
        </Wrapper>
    );
}
export default MyEnquiries;
