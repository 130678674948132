// import XLSX from "XLSX";

export const validateEmail = (email) => {
    const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regexp.test(email);
}

export const validateUrl = (url) => {
    const regexp = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
    return url.match(regexp);
}

export const CurrencyList = [
    { "id": 1, "text": 'India', "value": 'INR' },
    { "id": 1, "text": 'Australia', "value": 'AUD' },
    { "id": 1, "text": 'Bahrain', "value": 'BHD' },
    { "id": 1, "text": 'Bangladesh', "value": 'BDT' },
    { "id": 1, "text": 'Bhutan', "value": 'BTN' },
    { "id": 1, "text": 'Canada', "value": 'CAD' },
    { "id": 1, "text": 'Indonesia', "value": 'IDR' },
    { "id": 1, "text": 'Kuwait', "value": 'KWD' },
    { "id": 1, "text": 'Malaysia', "value": 'MYR' },
    { "id": 1, "text": 'Nepal', "value": 'NPR' },
    { "id": 1, "text": 'New Zealand', "value": 'NZD' },
    { "id": 1, "text": 'Oman', "value": 'OMR' },
    { "id": 1, "text": 'Pakistan', "value": 'PKR' },
    { "id": 1, "text": 'Phillipines', "value": 'PHP' },
    { "id": 1, "text": 'Saudi Arabia', "value": 'SAR' },
    { "id": 1, "text": 'Singapore', "value": 'SGD' },
    { "id": 1, "text": 'South Africa', "value": 'ZAR' },
    { "id": 1, "text": 'Srilanka', "value": 'LKR' },
    { "id": 1, "text": 'Thailand', "value": 'THB' },
    { "id": 1, "text": 'UAE', "value": 'AED' },
    { "id": 1, "text": 'UK', "value": 'GBP' },
    { "id": 1, "text": 'USA', "value": 'USD' }
]

export const Age = [
    { "id": 1, "text": 'Below 1', "value": 'Below 1' },
    { "id": 1, "text": '1-2 Year', "value": '1-2 Year' },
    { "id": 1, "text": '2-3 Year', "value": '2-3 Year' },
    { "id": 1, "text": '3-4 Year', "value": '3-4 Year' },
    { "id": 1, "text": '4-5 Year', "value": '4-5 Year' },
    { "id": 1, "text": '5-6 Year', "value": '5-6 Year' },
    { "id": 1, "text": '6-7 Year', "value": '6-7 Year' },
    { "id": 1, "text": '7-8 Year', "value": '7-8 Year' },
    { "id": 1, "text": '8-9 Year', "value": '8-9 Year' },
    { "id": 1, "text": '9-10 Year', "value": '9-10 Year' },
    { "id": 1, "text": '10-11 Year', "value": '10-11 Year' },
    { "id": 1, "text": '11-12 Year', "value": '11-12 Year' },
    { "id": 1, "text": '12-13 Year', "value": '12-13 Year' },
    { "id": 1, "text": '13-14 Year', "value": '13-14 Year' },
    { "id": 1, "text": '14-15 Year', "value": '14-15 Year' },
    { "id": 1, "text": 'Above 15 Year', "value": 'Above 15 Year' },
]

export const PetOptions = [
    { "id": 1, "text": 'Dog', "value": 'dog' },
    { "id": 2, "text": 'Cat', "value": 'cat' },
    { "id": 3, "text": 'Dog & Cat', "value": 'dog & cat' },
]

export const replaceUnderscore = (str) => {
    return str.replace(/_/g, ' ');

}
export const donwloadOptions = [
    { "type": 'xls', 'text': "Download xls" },
    { "type": 'xlsx', 'text': "Download xlsx" },
    // { "type": 'txt', 'text': "Download txt" },
    // { "type": 'json', 'text': "Download json" }
]

export const DownloadExcel = (
    data,
    fileName,
    type,
    e) => {
    console.log(data);
    // e.preventDefault();
    // const ws = XLSX.utils.json_to_sheet(data);
    // const wb = XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(wb, ws, fileName);
    // XLSX.writeFile(wb, fileName + '.' + type);
}

export default validateEmail
