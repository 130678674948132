/* eslint-disable no-empty-pattern */
import {
    GenderOption,
    TimeSlotPreference,
    groomers_service_type,
    ServicesOption,
    PaymentModeOption,
} from "../../components/utility";
import { useEffect, useState } from "react";
import { Button, Input, Select, Toggle } from "../../Library/Module";
import DatePickerComp from "../../Library/DatePicker/DatePicker";
import { localStorageParse } from "../../Context/ThemeContext";

type formDataProps = {
    [key: string]: string;
};

type Props = {
    showResult: any
    pet: any
    category: any
    userLogged: any
    area?: any
    scollToRef?: any
    handleClick?: any
}
const CatGroomers = ({
    showResult = false,
    pet,
    category,
    userLogged,
    area,
    scollToRef,
    handleClick
}: Props): JSX.Element => {
    const initialData = localStorageParse(pet + "_" + category)
    const [formData, setFormData] = useState<any>(initialData && JSON.parse(initialData));
    const [isValid, setIsValid] = useState(false)
    const onChangeSingleCallback = (data: any) => {
        setFormData((prevState:any) => ({
            ...prevState,
            ...data,
        }));
        showResult(false);
    };
    const Submit = () => {
        showResult(true);
        localStorage.setItem(pet + "_" + category, JSON.stringify(formData))
        if (scollToRef.current) {
            scollToRef.current.scrollIntoView()
        }
        handleClick?.()
    };
    useEffect(() => {
        if ((formData?.groomers_service_type && formData?.groomers_service_type === 'At Home' && formData?.groomers_service_type?.length > 0 &&
            formData?.Name_of_the_cat_parent && formData?.Name_of_the_cat_parent?.length > 0 &&
            formData?.address && formData?.address?.length > 0 &&
            formData?.contact_number && formData?.contact_number?.length > 0 &&
            formData?.cat_bread && formData?.cat_bread?.length > 0 &&
            formData?.gender && formData?.gender?.length > 0 &&
            formData?.Services && formData?.Services?.length > 0 &&
            formData?.time_slot_preference && formData?.time_slot_preference?.length > 0 &&
            formData?.payment && formData?.payment?.length > 0) ||
            (formData?.groomers_service_type && formData?.groomers_service_type === 'At Salon' && formData?.groomers_service_type?.length > 0 &&
                formData?.Name_of_the_cat_parent && formData?.Name_of_the_cat_parent?.length > 0 &&
                formData?.contact_number && formData?.contact_number?.length > 0 &&
                formData?.cat_bread && formData?.cat_bread?.length > 0 &&
                formData?.gender && formData?.gender?.length > 0 &&
                formData?.Services && formData?.Services?.length > 0 &&
                formData?.time_slot_preference && formData?.time_slot_preference?.length > 0) ||
            (formData?.groomers_service_type && formData?.groomers_service_type === 'In Van' && formData?.groomers_service_type?.length > 0 &&
                formData?.Name_of_the_cat_parent && formData?.Name_of_the_cat_parent?.length > 0 &&
                formData?.contact_number && formData?.contact_number?.length > 0 &&
                formData?.cat_bread && formData?.cat_bread?.length > 0 &&
                formData?.address && formData?.address?.length > 0 &&
                formData?.gender && formData?.gender?.length > 0 &&
                formData?.Services && formData?.Services?.length > 0 &&
                formData?.time_slot_preference && formData?.time_slot_preference?.length > 0)
        ) {
            if (userLogged === true) {
                setIsValid(true)
            } else {
                setIsValid(true)            }
        } else {
            setIsValid(false)
        }

    }, [formData, userLogged])
    return (
        <>
            <div className="row">
                <Select
                    inputName="groomers_service_type"
                    labelName="Service Type"
                    options={groomers_service_type ?? []}
                    onChangeSingleCallback={onChangeSingleCallback}
                    required={true}
                    placeholder="Select Groomers Service Type"
                    search_option={false}
                    col="3"
                    selectedItem={groomers_service_type?.find((item: any) => {
                        return item.value === formData?.groomers_service_type;
                    })}
                ></Select>

                {formData?.groomers_service_type === "At Home" ? (
                    <>
                        <Input
                            inputName="Name_of_the_cat_parent"
                            inputType="text"
                            labelName={"Name of the cat parent"}
                            placeholder="Enter Name of the cat parent"
                            required={true}
                            onChangeSingleCallback={onChangeSingleCallback}
                            col="3"
                            value={formData?.Name_of_the_cat_parent}
                        />
                        <Input
                            inputName="address"
                            inputType="text"
                            labelName={"Address"}
                            placeholder="Enter Address"
                            required={true}
                            onChangeSingleCallback={onChangeSingleCallback}
                            col="3"
                            value={formData?.address}
                        />
                        <Input
                            inputName="contact_number"
                            inputType="text"
                            labelName={"Contact number"}
                            placeholder="Enter Contact number"
                            required={true}
                            onChangeSingleCallback={onChangeSingleCallback}
                            col="3"
                            value={formData?.contact_number}
                        />
                        <Input
                            inputName="cat_bread"
                            inputType="text"
                            labelName={"Cat Breed"}
                            placeholder="Enter Cat Breed"
                            required={true}
                            onChangeSingleCallback={onChangeSingleCallback}
                            col="3"
                            value={formData?.cat_bread}
                        />

                        <Select
                            inputName="gender"
                            labelName="Gender"
                            options={GenderOption ?? []}
                            onChangeSingleCallback={onChangeSingleCallback}
                            required={true}
                            placeholder="Select Gender"
                            search_option={false}
                            col="3"
                            selectedItem={GenderOption?.find((item: any) => {
                                return item.value === formData?.gender;
                            })}
                        ></Select>
                        <Select
                            inputName="Services"
                            labelName="Services"
                            options={ServicesOption ?? []}
                            onChangeSingleCallback={onChangeSingleCallback}
                            required={true}
                            placeholder="Select Gender"
                            search_option={false}
                            col="3"
                            selectedItem={ServicesOption?.find((item: any) => {
                                return item.value === formData?.Services;
                            })}
                        ></Select>
                        <DatePickerComp
                            inputName="appointment_date"
                            labelName={"Appointment Date"}
                            placeholder="Enter appointment Date"
                            required={true}
                            onChangeSingleCallback={onChangeSingleCallback}
                            col="3"></DatePickerComp>
                        <Select
                            inputName="time_slot_preference"
                            labelName="Time slot preference"
                            options={TimeSlotPreference ?? []}
                            onChangeSingleCallback={onChangeSingleCallback}
                            required={true}
                            placeholder="Select Time slot preference"
                            search_option={false}
                            col="3"
                            selectedItem={TimeSlotPreference?.find((item: any) => {
                                return item.value === formData?.time_slot_preference;
                            })}
                        ></Select>
                        <Select
                            inputName="payment"
                            labelName="Payment"
                            options={PaymentModeOption ?? []}
                            onChangeSingleCallback={onChangeSingleCallback}
                            required={true}
                            placeholder="Select Payment"
                            search_option={false}
                            col="3"
                            selectedItem={PaymentModeOption?.find((item: any) => {
                                return item.value === formData?.payment;
                            })}
                        ></Select>
                    </>
                ) : formData?.groomers_service_type === "At Salon" ? (
                    <>
                        <Input
                            inputName="Name_of_the_cat_parent"
                            inputType="text"
                            labelName={"Name of the cat parent"}
                            placeholder="Enter Name of the cat parent"
                            required={true}
                            onChangeSingleCallback={onChangeSingleCallback}
                            col="3"
                            value={formData?.Name_of_the_cat_parent}
                        />

                        <Input
                            inputName="contact_number"
                            inputType="text"
                            labelName={"Contact number"}
                            placeholder="Enter Contact number"
                            required={true}
                            onChangeSingleCallback={onChangeSingleCallback}
                            col="3"
                            value={formData?.contact_number}
                        />
                        <Input
                            inputName="cat_bread"
                            inputType="text"
                            labelName={"Cat Breed"}
                            placeholder="Enter Cat Breed"
                            required={true}
                            onChangeSingleCallback={onChangeSingleCallback}
                            col="3"
                            value={formData?.cat_bread}
                        />
                        <Select
                            inputName="gender"
                            labelName="Gender"
                            options={GenderOption ?? []}
                            onChangeSingleCallback={onChangeSingleCallback}
                            required={true}
                            placeholder="Select Gender"
                            search_option={false}
                            col="3"
                            selectedItem={GenderOption?.find((item: any) => {
                                return item.value === formData?.gender;
                            })}
                        ></Select>
                        <Select
                            inputName="Services"
                            labelName="Services"
                            options={ServicesOption ?? []}
                            onChangeSingleCallback={onChangeSingleCallback}
                            required={true}
                            placeholder="Select Gender"
                            search_option={false}
                            col="3"
                            selectedItem={ServicesOption?.find((item: any) => {
                                return item.value === formData?.Services;
                            })}
                        ></Select>
                        <DatePickerComp
                            inputName="appointment_date"
                            labelName={"Appointment Date"}
                            placeholder="Enter appointment Date"
                            required={true}
                            onChangeSingleCallback={onChangeSingleCallback}
                            col="3"></DatePickerComp>
                        <Select
                            inputName="time_slot_preference"
                            labelName="Time slot preference"
                            options={TimeSlotPreference ?? []}
                            onChangeSingleCallback={onChangeSingleCallback}
                            required={true}
                            placeholder="Select Time slot preference"
                            search_option={false}
                            col="3"
                            selectedItem={TimeSlotPreference?.find((item: any) => {
                                return item.value === formData?.time_slot_preference;
                            })}
                        ></Select>
                    </>
                ) : formData?.groomers_service_type === "In Van" ? (
                    <>
                        <Input
                            inputName="Name_of_the_cat_parent"
                            inputType="text"
                            labelName={"Name of the cat parent"}
                            placeholder="Enter Name of the cat parent"
                            required={true}
                            onChangeSingleCallback={onChangeSingleCallback}
                            col="3"
                            value={formData?.Name_of_the_cat_parent}
                        />
                        <Input
                            inputName="address"
                            inputType="text"
                            labelName={"Address"}
                            placeholder="Enter Address"
                            required={true}
                            onChangeSingleCallback={onChangeSingleCallback}
                            col="3"
                            value={formData?.address}
                        />
                        <Input
                            inputName="contact_number"
                            inputType="text"
                            labelName={"Contact number"}
                            placeholder="Enter Contact number"
                            required={true}
                            onChangeSingleCallback={onChangeSingleCallback}
                            col="3"
                            value={formData?.contact_number}
                        />
                        <Input
                            inputName="cat_bread"
                            inputType="text"
                            labelName={"Cat Breed"}
                            placeholder="Enter Cat Breed"
                            required={true}
                            onChangeSingleCallback={onChangeSingleCallback}
                            col="3"
                            value={formData?.cat_bread}
                        />
                        <Select
                            inputName="gender"
                            labelName="Gender"
                            options={GenderOption ?? []}
                            onChangeSingleCallback={onChangeSingleCallback}
                            required={true}
                            placeholder="Select Gender"
                            search_option={false}
                            col="3"
                            selectedItem={GenderOption?.find((item: any) => {
                                return item.value === formData?.gender;
                            })}
                        ></Select>
                        <Select
                            inputName="Services"
                            labelName="Services"
                            options={ServicesOption ?? []}
                            onChangeSingleCallback={onChangeSingleCallback}
                            required={true}
                            placeholder="Select Gender"
                            search_option={false}
                            col="3"
                            selectedItem={ServicesOption?.find((item: any) => {
                                return item.value === formData?.Services;
                            })}
                        ></Select>
                        <DatePickerComp
                            inputName="appointment_date"
                            labelName={"Appointment Date"}
                            placeholder="Enter appointment Date"
                            required={true}
                            onChangeSingleCallback={onChangeSingleCallback}
                            col="3"></DatePickerComp>
                        <Select
                            inputName="time_slot_preference"
                            labelName="Time slot preference"
                            options={TimeSlotPreference ?? []}
                            onChangeSingleCallback={onChangeSingleCallback}
                            required={true}
                            placeholder="Select Time slot preference"
                            search_option={false}
                            col="3"
                            selectedItem={TimeSlotPreference?.find((item: any) => {
                                return item.value === formData?.time_slot_preference;
                            })}
                        ></Select>
                        <Select
                            inputName="Payment"
                            labelName="Payment"
                            options={PaymentModeOption ?? []}
                            onChangeSingleCallback={onChangeSingleCallback}
                            required={true}
                            placeholder="Select Payment"
                            search_option={false}
                            col="3"
                            selectedItem={PaymentModeOption?.find((item: any) => {
                                return item.value === formData?.Payment;
                            })}
                        ></Select>
                    </>
                ) : (
                    ""
                )}
                <Select
                    inputName="area"
                    labelName="Area"
                    options={area ?? []}
                    onChangeSingleCallback={onChangeSingleCallback}
                    required={true}
                    placeholder="Select Area"
                    search_option={false}
                    col="3"
                    selectedItem={area?.find((item: any) => {
                        return item.value === formData?.area;
                    })}
                ></Select>
            </div>
            <div className="row align-items-center">
                <div className="col-sm-2">
                    <Button
                        onClick={() => Submit()}
                        buttonText={"Search"}
                        buttonStyleType={"primary"}
                        buttonWidth="sm"
                        disabled={!isValid}
                    />
                </div>
            </div>
        </>
    )
};
export default CatGroomers;