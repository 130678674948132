import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ServiceDogList } from "../components/utility";
import { localStorageParse } from "../Context/ThemeContext";
import Loader from "../Library/Loader/Loader";
import { formDataProps } from "../servies/CommonTypes";


type ServicesType = {
  pawzwhiskers_preference?: formDataProps;
};
const Services = ({ serviceList, loadingServiceList = false }: {
  serviceList: any, loadingServiceList: any
}) => {
  const navigate = useNavigate();

  const pet_prefernce = localStorageParse('pet_prefernce')
  const parse = pet_prefernce && JSON.parse(pet_prefernce)
  console.log("parse", parse?.pet)
  const ServiesList = (URL: any) => {
    navigate(`/service-details/${parse?.pet}/${URL}`);
  };

  return (
    <div className="servicesBox">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-sm-12 col-md-12">
            <div className="inner">
              <div className="col-sm-12 col-md-2">
                <h3 className="section_title">
                  <span>Our Services</span>
                </h3>
              </div>
              <div className="row">
                {!loadingServiceList ? <Loader loaderType={'pageLoader'}></Loader> :
                  serviceList && serviceList?.map((item: any) => {
                    console.log(item)
                    return (
                      <div
                        className="col cursor-pointer"
                        onClick={() => ServiesList(item.slug)}
                      >
                        <div className="item">
                          <div className="placeholder_icon">
                            <img src={process.env.react_app_base_url + "/" + item?.image} alt="" title="" />
                          </div>
                          <h3>{item.title}</h3>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Services;
