/* eslint-disable no-empty-pattern */
import { useEffect, useState } from "react";
import DatePickerComp from "../../Library/DatePicker/DatePicker";
import { Button, Input, Select } from "../../Library/Module";
import { InsuranceEquiry } from "../../servies/services";
import { toast } from "react-toastify";
import axios from "axios";
import { localStorageParse } from "../../Context/ThemeContext";

type formDataProps = {
    [key: string]: string;
};

type Props = {
    showResult: any
    pet: any
    category: any
    userLogged: any
    area: any
    scollToRef?: any
    handleClick?: any
}
const Insurance = ({
    showResult = false,
    pet,
    category,
    userLogged,
    area,
    scollToRef,
    handleClick
}: Props): JSX.Element => {
    const [isFormSubmitted, setIsFormSubmitted]: any = useState(false);
    const [disabled, setDisabled] = useState(true);
    const initialData = localStorageParse(pet + "_" + category)
    const [formData, setFormData] = useState<any>(initialData && JSON.parse(initialData));
    const [isValid, setIsValid] = useState(false)
    const onChangeSingleCallback = (data: any) => {
        setFormData((prevState:any) => ({
            ...prevState,
            ...data,
        }));
        showResult(false);
    };

    const Submit = async () => {

        await axios
            .post(`https://pawzwhiskers.com/nodeapi/api/v1/user/insurance_equiry`, formData)
            .then((response) => {
                toast.success('Your enquiry has been submitted successfully.', {
                    position: toast.POSITION.TOP_CENTER
                });
                setFormData({
                    full_name: '',
                    dog_breed: '',
                    age: '',
                    phone_number: '',
                    commnet: "",
                    email_id: ''
                });
            })
            .catch(function (error) {
                console.log(error.response?.data?.messages)
                if (error.response?.data?.messages) {
                    toast.error(error.response?.data?.messages);
                } else {
                    toast.error("Something went wrong, please try again");
                }
            });


        // const APIResponse = await InsuranceEquiry(formData);
        // if (APIResponse?.data?.isSuccess === true) {
        //     toast.success('Your enquiry has been submitted successfully.', {
        //         position: toast.POSITION.TOP_CENTER
        //     });


        // } else {
        //     toast.error("Something went wrong, please try again");

        // }
    }

    useEffect(() => {
        if (formData?.full_name && formData?.full_name?.length > 0 &&
            formData?.dog_breed && formData?.dog_breed?.length > 0 &&
            formData?.age && formData?.age?.length > 0 &&
            formData?.phone_number && formData?.phone_number?.length > 0 &&
            formData?.email_id && formData?.email_id?.length > 0) {
            setDisabled(false)
        }

    }, [formData])

    return (
        <>
            <div className="contact-form" style={{ maxWidth: '850px' }}>
                <h3>Insurance</h3>
                <p>Let's get this conversation started, Tell us bit about yourself and we'll get in touch as soon as we can.</p>
                <div className="row">
                    <div className="col-md-6">
                        <Input
                            inputName="full_name"
                            inputType="text"
                            labelName={"Full Name"}
                            placeholder="Enter Full Name"
                            required={true}
                            onChangeSingleCallback={onChangeSingleCallback}
                            col="12"
                            isFormSubmitted={isFormSubmitted}
                            value={formData?.full_name}
                        />

                        <Input
                            inputName="dog_breed"
                            inputType="text"
                            labelName={"Dog Breed"}
                            placeholder="Enter Dog Breed"
                            required={true}
                            onChangeSingleCallback={onChangeSingleCallback}
                            col="12"
                            isFormSubmitted={isFormSubmitted}
                            value={formData?.dog_breed}
                        />


                        <Input
                            inputName="age"
                            inputType="number"
                            labelName={"Age"}
                            placeholder="Enter Age"
                            required={true}
                            onChangeSingleCallback={onChangeSingleCallback}
                            col="12"
                            isFormSubmitted={isFormSubmitted}
                            value={formData?.age}
                        />

                    </div>
                    <div className="col-md-6">
                        <Input
                            inputName="email_id"
                            inputType="email"
                            labelName={"Email ID"}
                            placeholder="Enter Email ID"
                            required={true}
                            onChangeSingleCallback={onChangeSingleCallback}
                            col="12"
                            isFormSubmitted={isFormSubmitted}
                            value={formData?.email_id}
                        />

                        <Input
                            inputName="phone_number"
                            inputType="text"
                            labelName={"Phone Number"}
                            placeholder="Enter Phone Number"
                            required={true}
                            onChangeSingleCallback={onChangeSingleCallback}
                            col="12"
                            isFormSubmitted={isFormSubmitted}
                            value={formData?.phone_number}
                        />

                        <Input
                            inputName="commnet"
                            inputType="textarea"
                            labelName={"Comment"}
                            placeholder="Enter Comment"
                            required={false}
                            onChangeSingleCallback={onChangeSingleCallback}
                            col="12"
                            isFormSubmitted={isFormSubmitted}
                            value={formData?.commnet}
                        />
                    </div>
                </div>

                <Button
                    onClick={() => Submit()}
                    buttonText={"Submit"}
                    buttonStyleType={"primary"}
                    disabled={disabled}
                    className="float-right"
                />
            </div>
        </>
    )
};
export default Insurance;