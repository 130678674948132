/* eslint-disable no-empty-pattern */
import Wrapper from "../Wrapper";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState, useRef, useCallback } from "react";
import { Alert, Button, } from "../../Library/Module";
import { Item } from "./Item";
import { CategoryBySubCategory, CategorySubBySlug, GetCityAreaService } from "../../servies/services";
import { toast } from "react-toastify";
import FindVetDog from '../Forms/FindVet'
import DogGroomers from '../Forms/DogGroomers'
import Walkers from '../Forms/Walkers'
import Hostels from '../Forms/Hostels'
import Trainers from '../Forms/Trainers'
import FosterHomes from '../Forms/FosterHomes'
import MealProvider from '../Forms/MealProvider'
import Common from '../Forms/Common'
import Loader from "../../Library/Loader/Loader";
import { localStorageParse } from "../../Context/ThemeContext";
import CatFindVet from "../Forms/CatFindVet";
import CatGroomers from "../Forms/CatGroomers";
import CatHostels from "../Forms/CatHostels";
import CatFosterHomes from "../Forms/CatFosterHomes";
import Behaviourist from "../Forms/Behaviourist";
import Taxi from "../Forms/Taxi";
import Insurance from "../Forms/Insurance";
import CatTaxi from "../Forms/CatTaxi";


interface StateData {
  [x: string]: string;
}
type formDataProps = {
  title: string;
  slug: string;
  content: string;
  groomers_service_type: string;
};

const ServicesDetails = () => {
  const navigate = useNavigate();
  const [showResult, setShowResult] = useState(false);
  let { id, type, categry, serviceElement } = useParams();
  const [isLoading, setIsLoading]: any = useState(false);
  const [isMainLoading, setIsMainLoading]: any = useState(false);
  const [data, setData]: any = useState([]);
  const [isError, setIsError] = useState(false);
  const [listData, setListData]: any = useState([]);
  const [showForm, setShowForm]: any = useState(false);

  const frontendUserData = localStorage.getItem('frontendUserData')
  console.log("frontendUserData", frontendUserData)
  const userData = frontendUserData !== null ? JSON.parse(frontendUserData) : undefined
  const userLogged = userData?.email_id === undefined ? false : true
  console.log("userData", userData?.email_id)


  const pet_prefernce = localStorageParse('pet_prefernce')
  const parse = pet_prefernce && JSON.parse(pet_prefernce)
  console.log("parse", parse?.pet)


  const city_prefernce = localStorageParse('city_prefernce')
  const city_parse = city_prefernce && JSON.parse(city_prefernce)
  const cityName = city_parse?.city
  console.log("city_parse", city_parse?.city)

  const [areaList, setAreaList] = useState([]);
  const scollToRef = useRef<any>(null);

  const ServiesList = (URL: any) => {
    navigate(`/service-details/${parse?.pet}/${URL}`);
  };

  const [formData, setFormData] = useState<formDataProps>();
  const onChangeSingleCallback = (data: any) => {
    setFormData((prevState) => ({
      ...prevState,
      ...data,
    }));
  };
  const Submit = () => {
    setShowResult(true);
  };
  useEffect(() => console.log("scollToRef.current", scollToRef.current), [])

  useEffect(() => {
    if (serviceElement) {
      setShowResult(false)
      setIsMainLoading(false);
      const getData = async () => {
        const APIResponse = await CategorySubBySlug(serviceElement);
        if (APIResponse?.data?.isSuccess === true) {
          setData(APIResponse?.data?.data);
          setIsMainLoading(true);
        } else {
          toast.error("Something went wrong, please try again");
          setIsError(true);
          setIsMainLoading(true);
        }
      };
      getData();
    }
  }, [serviceElement]);

  useEffect(() => {
    if (categry) {
      setIsLoading(false);
      const getData = async () => {
        const APIResponse = await CategoryBySubCategory(categry);
        if (APIResponse?.data?.isSuccess === true) {
          setListData(APIResponse?.data?.data);
          setIsLoading(true);
        } else {
          toast.error("Something went wrong, please try again");
          setIsError(true);
          setIsLoading(true);
        }
      };
      getData();
    }
  }, [categry]);

  useEffect(() => {

    const getData = async () => {
      const APIResponse = await GetCityAreaService(cityName);
      if (APIResponse?.data?.isSuccess === true) {
        console.log("APIResponse CategoryByCMSType", APIResponse?.data?.data)
        let updatedList: any = [{ text: 'Any', id: 0, value: 'any' }]
        APIResponse?.data?.data?.map((item: any) => {
          return updatedList.push({ text: item?.title, id: item?._id, value: item?.slug })
        })
        setAreaList(updatedList);
      } else {
        toast.error("Something went wrong, please try again");
        setIsLoading(true);
      }
    };
    getData();

  }, [cityName])

  const ref = useRef<any>(null);

  const handleClick = () => {
    console.log("Asdasdasdasd", ref.current?.scrollIntoView)
    ref.current?.scrollIntoView({ behavior: 'smooth' });
    setShowForm(true)
  }

  return (
    <Wrapper>
      <div className="">
        <div className="subNav">
          <div className="container">
            <div className="row align-items-center">
              <ul className="userList">
                {listData && listData?.map((item: any) => {
                  return (
                    <li>
                      <NavLink to={`/service-details/${parse?.pet}/${item?.slug}`}>
                        <p>{item?.title}</p>
                      </NavLink>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>

        {!isLoading && !isMainLoading ? <Loader loaderType={'pageLoader'}></Loader> :
          <>
            <div className="pageTitle">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col">
                    <div className="innerPageTitle">
                      <h1>{data?.title}</h1>
                      <div className="dynamic_content" dangerouslySetInnerHTML={{ __html: data?.description }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="search_form">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-md-12">
                    {categry === 'dog' && serviceElement === "insurance" ? '' : <h2>Search {data?.title} </h2>}
                  </div>
                </div>

                <div className="row align-items-center">
                  {categry === 'dog' && serviceElement === "vets" ? (
                    <>
                      <FindVetDog handleClick={handleClick} scollToRef={scollToRef} area={areaList} userLogged={userData?.email_id === undefined ? false : true} pet={categry} category={serviceElement} showResult={setShowResult}></FindVetDog>
                    </>
                  ) : categry === 'dog' && serviceElement === "groomers" ? (
                    <>
                      <DogGroomers handleClick={handleClick} scollToRef={scollToRef} area={areaList} userLogged={userData?.email_id === undefined ? false : true} pet={categry} category={serviceElement} showResult={setShowResult}></DogGroomers>
                    </>
                  ) : categry === 'dog' && serviceElement === "dog-behaviourist" ? (
                    <>
                      <Behaviourist handleClick={handleClick} scollToRef={scollToRef} area={areaList} userLogged={userData?.email_id === undefined ? false : true} pet={categry} category={serviceElement} showResult={setShowResult}></Behaviourist>
                    </>
                  ) : categry === 'dog' && serviceElement === "pet-taxi" ? (
                    <>
                      <Taxi handleClick={handleClick} scollToRef={scollToRef} area={areaList} userLogged={userData?.email_id === undefined ? false : true} pet={categry} category={serviceElement} showResult={setShowResult}></Taxi>
                    </>
                  ) : categry === 'dog' && serviceElement === "dog-walkers" ? (
                    <>
                      <Walkers handleClick={handleClick} scollToRef={scollToRef} area={areaList} userLogged={userData?.email_id === undefined ? false : true} pet={categry} category={serviceElement} showResult={setShowResult}></Walkers>
                    </>
                  ) : categry === 'dog' && serviceElement === "dog-hostels" ? (
                    <>
                      <Hostels handleClick={handleClick} scollToRef={scollToRef} area={areaList} userLogged={userData?.email_id === undefined ? false : true} pet={categry} category={serviceElement} showResult={setShowResult}></Hostels>
                    </>
                  ) : categry === 'dog' && serviceElement === "trainers" ? (
                    <>
                      <Trainers handleClick={handleClick} scollToRef={scollToRef} area={areaList} userLogged={userData?.email_id === undefined ? false : true} pet={categry} category={serviceElement} showResult={setShowResult}></Trainers>
                    </>
                  ) : categry === 'dog' && serviceElement === "dog-sitters" ? (
                    <>
                      <FosterHomes handleClick={handleClick} scollToRef={scollToRef} area={areaList} userLogged={userData?.email_id === undefined ? false : true} pet={categry} category={serviceElement} showResult={setShowResult}></FosterHomes>
                    </>
                  ) : categry === 'dog' && serviceElement === "meal-providers" ? (
                    <>
                      <MealProvider handleClick={handleClick} scollToRef={scollToRef} area={areaList} userLogged={userData?.email_id === undefined ? false : true} pet={categry} category={serviceElement} showResult={setShowResult}></MealProvider>

                    </>
                  ) : categry === 'dog' && serviceElement === "insurance" ? (
                    <>
                      <Insurance handleClick={handleClick} scollToRef={scollToRef} area={areaList} userLogged={userData?.email_id === undefined ? false : true} pet={categry} category={serviceElement} showResult={setShowResult}></Insurance>
                    </>
                  )
                    : categry === 'cat' && serviceElement === "veterinarians" ? (
                      <>
                        <CatFindVet handleClick={handleClick} scollToRef={scollToRef} area={areaList} userLogged={userData?.email_id === undefined ? false : true} pet={categry} category={serviceElement} showResult={setShowResult}></CatFindVet>
                      </>
                    ) : categry === 'cat' && serviceElement === "groomers" ? (
                      <>
                        <CatGroomers handleClick={handleClick} scollToRef={scollToRef} area={areaList} userLogged={userData?.email_id === undefined ? false : true} pet={categry} category={serviceElement} showResult={setShowResult}></CatGroomers>
                      </>
                    ) : categry === 'cat' && serviceElement === "hostels" ? (
                      <>
                        <CatHostels handleClick={handleClick} scollToRef={scollToRef} area={areaList} userLogged={userData?.email_id === undefined ? false : true} pet={categry} category={serviceElement} showResult={setShowResult}></CatHostels>
                      </>

                    ) : categry === 'cat' && serviceElement === "cat-foster-homes" ? (
                      <>
                        <CatFosterHomes handleClick={handleClick} scollToRef={scollToRef} area={areaList} userLogged={userData?.email_id === undefined ? false : true} pet={categry} category={serviceElement} showResult={setShowResult}></CatFosterHomes>
                      </>
                    ) : categry === 'cat' && serviceElement === "pet-taxi" ? (
                      <>
                        <CatTaxi handleClick={handleClick} scollToRef={scollToRef} area={areaList} userLogged={userData?.email_id === undefined ? false : true} pet={categry} category={serviceElement} showResult={setShowResult}></CatTaxi>
                      </>
                    ) : (
                      <Common handleClick={handleClick} scollToRef={scollToRef} area={areaList} userLogged={userData?.email_id === undefined ? false : true} pet={categry} category={serviceElement} showResult={setShowResult}></Common>
                    )}
                </div>

                {/* {!userLogged && serviceElement !== "insurance" &&
                  <div className="row align-items-center mt-4">
                    <div className="col-sm-12">
                      <div className="noDataFound red">
                        <span className="material-symbols-outlined">
                          error
                        </span>
                        Click here to <NavLink to="/login"> Login</NavLink> OR <NavLink to="/register"> Register</NavLink> & see the results</div>
                    </div>
                  </div>
                } */}

              </div>
            </div>
          </>}
        <div ref={ref} className="search_results">
          {showResult && (
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-12">
                  <h2>Search results for {data?.title}</h2>
                </div>
              </div>
              <div className="row">
                <Item
                  pet_category={categry}
                  service_category={serviceElement}
                  city={cityName}
                  pageTitle={data?.title}
                  userLogged={true}
                ></Item>
              </div>
            </div>
          )}
        </div>
      </div>
    </Wrapper>
  );
};
export default ServicesDetails;
