import SIcon1 from "../images/vets.png";

import DogVet from '../images/Icon/DogVets.png'
import DogGroomer from '../images/Icon/DogGroomer.png'
import DogWalkers from '../images/Icon/DogWalkers.png'
import DogBoardersHostels from '../images/Icon/DogBoardersHostels.png'
import DogTrainers from '../images/Icon/DogTrainers.png'

import FosterHomes from '../images/Icon/FosterHomes.png'
import DogHostels from '../images/Icon/DogHostels.png'
import MealProvider from '../images/Icon/MealProvider.png'


export const ServiceDogList = [
  {
    title: "Find a Vet",
    slug: "find-a-vet",
    icon: DogVet,
    link: "/find-a-vet",
    count: "100",
    content: `
  <p>They say, “The best Doctors in the world are Veterinarians. They can't ask their patients, what is the matter. They just got to know”. </p>
  <p>Hence choosing a Vet for your pet is just as critical as choosing a paediatrician for your child. At PAWZWHISKERS, you can pick the best veterinary clinic in and around your locality or based on species and breed specific requirements.</p>`,
  },
  {
    title: "Groomers",
    slug: "groomers",
    icon: DogGroomer,
    link: "/groomers",
    count: "100",
    content: `
  <p>Grooming your pet is absolutely an essential part of pet care. Regular grooming not only helps in maintaining cute appearances with a shiny coat, clipped nails and clean ears but also helps in getting rid of shedding, fleas, ticks and various other health conditions. For cats especially, it helps remove loose fur and dirt while smoothening out the mats and tangles and redistribution of the natural oils. Another extremely significant part of grooming is early detection of any underlying health conditions such as rashes, inflammation, lumps, infections, lesions etc. A groomer can identify potential unhealthy conditions thus facilitating early detection and treatment. </p>
  <p>But to select the right Pet Grooming service is the key to a good and healthy looking pet. Your pet must also feel comfortable while being groomed, for which you can select a home grooming service. And if your pet likes to make a picnic out of grooming day, you can select a grooming salon.</p>`,
  },
  {
    title: "Dog Walkers",
    icon: DogWalkers,
    link: "/dog-walkers",
    count: "100",
    slug: "dog-walkers",
    content: `
<p>Even though you may love to walk your own dog, here are some benefits of hiring a Dog Walker- </p>
<p>Health - Just like humans, pets need regular and consistent exercise to live long, healthy, and happy lives. Many pets tend to acquire specific problems such as obesity which can easily be prevented or cured with proper exercise.</p>
<p>Socialization and Stimulation - Walking can get your dog to see, smell, feel, and hear all types of new and interesting things when out for a walk.  This stimulation often includes meeting other people or animals, which can be an excellent way to encourage proper socialization.</p>
<p>Behavior - Regular walking helps to manage excess energy of your pet, resulting in calmer behavior at home. </p>
<p>Peace of Mind - Hiring a dog walker gives you the peace of mind that your dog is well taken care of, even when you aren’t at home.  </p>
<p>Time - With a dog walker, you can ensure consistency and rotuine for your furry baby </p>
<p>To check Dog Walkers near you, log in/ sign up </p>

`,
  },
  {
    title: "Dog Hostels",
    icon: DogBoardersHostels,
    link: "/dog-hostels",
    count: "132",
    slug: "dog-hostels",
    content: "",
  },
  {
    title: "Dog Trainers",
    icon: DogTrainers,
    link: "/dog-trainers",
    count: "145",
    slug: "dog-trainers",
    content: `
    <p>Training plays a pivotal role in responsible pet parenting. A well behaved pet is the pride of every pet parent. And this can be achieved through proper professional training. A professional trainer can help you give a critical feedback about your pet's behaviour further providing a creative solution on how to tackle it. Trainers are experienced to view behaviour of your pet in an objective light. </p>
`,
  },
  {
    title: "Dog Adoption",
    icon: DogHostels,
    link: "/dog-adoption",
    count: "19",
    slug: "dog-adoption",
    content: "",
  },
  {
    title: "Dog Foster Homes",
    icon: FosterHomes,
    link: "/dog-foster-homes",
    count: "140",
    slug: "dog-foster-homes",
    content: `
    <p>Difference could be - Someone keeping the dog or cat with their family as compare to hostels where they have kept in kennels/cages. </p>
    <p>This page will have search filters and search results will be shown to the logged in users. If user is not logged in they will be promoted to either log in or sign up with the website. </p>
    `,
  },
  {
    title: "Dog Meal Provider",
    icon: MealProvider,
    link: "/dog-meal-provider",
    count: "190",
    slug: "dog-meal-provider",
    content: "",
  },
];

export const ServiceCatList = [
  {
    title: "Find a Vet",
    icon: SIcon1,
    link: "/",
    count: "100",
    slug: "cat-find-a-vet",
    content: "",
  },
  {
    title: "Groomers",
    icon: SIcon1,
    link: "/",
    count: "110",
    slug: "cat-find-a-vet",
    content: "",
  },
  {
    title: "Hostels",
    icon: SIcon1,
    link: "/",
    count: "132",
    slug: "cat-find-a-vet",
    content: "",
  },
  {
    title: "Cat Adoption",
    icon: SIcon1,
    link: "/",
    count: "145",
    slug: "cat-find-a-vet",
    content: "",
  },
  {
    title: "Cat Foster Homes",
    icon: SIcon1,
    link: "/",
    count: "190",
    slug: "cat-find-a-vet",
    content: "",
  },
];

export const DogBreadOption = [
  { text: "Labrador Retriever", id: "1", value: "Labrador Retriever" },
  { text: "German Shepherd", id: "2", value: "German Shepherd" },
  { text: "Golden Retriever", id: "3", value: "Golden Retriever" },
  { text: "Dachshund", id: "4", value: "Dachshund" },
  { text: "Beagle", id: "5", value: "Beagle" },
];

export const GenderOption = [
  { text: "Male", id: "1", value: "Male" },
  { text: "Female", id: "2", value: "Female" }
];

export const AgeOption = [
  { text: "1", id: "1", value: "1" },
  { text: "2", id: "2", value: "2" },
  { text: "3", id: "3", value: "3" },
  { text: "4", id: "4", value: "4" },
  { text: "5", id: "5", value: "5" },
  { text: "6", id: "6", value: "6" },
  { text: "7", id: "7", value: "7" },
  { text: "8", id: "8", value: "8" },
  { text: "9", id: "9", value: "9" },
  { text: "10", id: "10", value: "10" },
  { text: "11", id: "11", value: "11" },
  { text: "12", id: "12", value: "12" },
  { text: "13", id: "13", value: "13" },
  { text: "14", id: "14", value: "14" },
  { text: "15", id: "15", value: "15" },
  { text: "16", id: "16", value: "16" },
  { text: "17", id: "17", value: "17" },
  { text: "18", id: "18", value: "18" },
  { text: "19", id: "19", value: "19" },
  { text: "20", id: "20", value: "20" },
  { text: "21", id: "21", value: "21" },
  { text: "22", id: "22", value: "22" },
];

export const TimeSlotPreference = [
  { text: "9AM to 12AM", id: "1", value: "9AM	to 12AM" },
  { text: "12PM to 3PM", id: "1", value: "12PM to 3PM" },
  { text: "3PM to	6PM", id: "1", value: "3PM to	6PM" },
  { text: "6PM to 9pm", id: "1", value: "6PM to 9pm" },
];

export const dog_temperament = [
  { text: "Active", id: "1", value: "Active" },
  { text: "Hyper", id: "2", value: "Hyper" },
  { text: "Aggressive", id: "3", value: "Aggressive" },
  { text: "calm", id: "4", value: "calm" },
];

export const cat_temperament = [
  { text: "Active", id: "1", value: "Active" },
  { text: "Hyper", id: "2", value: "Hyper" },
  { text: "Aggressive", id: "3", value: "Aggressive" },
  { text: "calm", id: "4", value: "calm" },
];


export const DogWalkerServices = [
  { text: "Monthly", id: "1", value: "Monthly" },
  { text: "Short Period", id: "2", value: "Short Period" },
];

export const TrainingRequirement = [
  { text: "Basic	training", id: "1", value: "Basic	training" },
  { text: "Advanced	training", id: "2", value: "Advanced	training" },
];

export const VaccineStatus = [
  { text: "Yes", id: "1", value: "Yes" },
  { text: "No", id: "2", value: "No" },
];

export const ApartmentType = [
  { text: "Bungalow", id: "1", value: "Bungalow" },
  { text: "Apartment", id: "2", value: "Apartment" },
];

export const ServiceType = [
  { text: "Free", id: "1", value: "Free" },
  { text: "Paid", id: "2", value: "Paid" },
];

export const groomers_service_type = [
  { text: "At Home", id: "1", value: "At Home" },
  { text: "At Salon", id: "2", value: "At Salon" },
  { text: "In Van", id: "2", value: "In Van" },
];

export const ServicesOption = [
  { text: "Option 1", id: "1", value: "Option 1" },
];

export const PaymentModeOption = [
  { text: "Online", id: "1", value: "Online" },
  { text: "Offline", id: "2", value: "Offline" },
  { text: "Online-Offline", id: "3", value: "Online-Offline" },
];

export const PetsOption = [
  { text: "Cat", id: "1", value: "cat" },
  { text: "Dog", id: "1", value: "dog" },
]

export const objectToQueryString = (params:any) => {
  return Object.keys(params).map(key => key + '=' + params[key]).join('&');
}