/* eslint-disable no-empty-pattern */
import { Button } from "../../Library/Module";
import Wrapper from "../Wrapper";
import AboutUsImage from "../../images/aboutUs2.png";
import placeholder from "../../images/placeholder.png";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { CMSBySlug } from "../../servies/services";
import { toast } from "react-toastify";
import Loader from "../../Library/Loader/Loader";

const AboutUs = () => {
  let { id, type, action } = useParams();
  const [isLoading, setIsLoading]: any = useState(false);
  const [data, setData]: any = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setIsLoading(false);
    const getData = async () => {
      const APIResponse = await CMSBySlug('about-us');
      if (APIResponse?.data?.isSuccess === true) {
        setData(APIResponse?.data?.data);
        setIsLoading(true);
      } else {
        toast.error("Something went wrong, please try again");
        setIsError(true);
        setIsLoading(true);
      }
    };
    getData();
  }, []);

  return (
    <Wrapper>
      {/* {isLoading &&
        <>
          <div className="pageTitle">
            <div className="container">
              <div className="row align-items-center">
                <div className="col">
                  <div className="innerPageTitle">
                    <h1>{data?.title}</h1>
                    <div dangerouslySetInnerHTML={{ __html: data?.short_description }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="aboutUs">
          <div dangerouslySetInnerHTML={{ __html: data?.description }} />
          </div>
        </>
      } */}
      {!isLoading && <Loader loaderType={'pageLoader'}></Loader>}
      <div className="pageTitle">
        <div className="container">
          <div className="row align-items-center">
            <div className="col">
              <div className="innerPageTitle">
                <h1>About Us</h1>
                <p>
                  Pawzwhiskers is a one stop solution for all your pet related
                  requirements. We know that you only want the best for your four
                  legged baby, and we are here, to help you achieve that.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="aboutUs">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-sm-5 col-md-5 ">
              <div className="position-relative imageAboutUsPlaceholder">
                <div className="imageAboutUs"></div>
                <img src={AboutUsImage} title="" alt="" />
              </div>
            </div>
            <div className="col-sm-6 col-md-6">
              <h2>
                <span>What is Pawzwhiskers</span>
              </h2>
              <p>
                Pawzwhiskers is a one stop solution for all your pet related
                requirements. We know that you only want the best for your four
                legged baby, and we are here, to help you achieve that.
              </p>
              <p>
                Bringing home a pet comes with its own set of challenges and
                making the right choices is the biggest of them all. One wrong
                experience may disturb your pet's psyche, further putting them
                in irreversible distress.
              </p>
              <p>
                At Pawzwhiskers, you can find every important Information about
                raising your pet right and connect with only genuine resources
                to be that perfect Pet Parent.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="aboutUs" style={{ background: "white" }}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-sm-6 col-md-6">
              <h2>
                <span>How we can help</span>
              </h2>
              <p>
                Nothing proves unconditional love for your pet like responsible
                pet parenting. And nothing proves responsible pet parenting like
                choosing the best of the best for your pet. But how can you
                choose the best if you don't know what is available?
              </p>
              <p>
                By registering with PAWZWHISKERS, you can get access to genuine
                and verified resources you need as a pet parent. Right from
                finding the best Veterinary Clinic close to you, to hiring a
                knowledgeable dog trainer, and even finding an efficient dog
                walker.
              </p>
            </div>
            <div className="col-sm-5 col-md-5 text-right ">
              <img src={placeholder} title="" alt="" />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
export default AboutUs;
