import React from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { GetMedia, CreateMedia } from "../../servies/services";
// import { GetMedia, CreateMedia } from "../../servies/services";
import { LazyLoadImage, trackWindowScroll } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
//  FilePond
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { Alert, Card, Input, Button, Select } from "../../Library/Module";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div p={3}>
                    <div>{children}</div>
                </div>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

class MediaPopup extends React.PureComponent {
    state = {
        tableData: [],
        open: false,
        files: [],
        loading: false,
        theme: {
            direction: "x-reverse"
        },
        value: 0
    };

    handleInit() {
        console.log("FilePond instance has initialised", this.pond);
    }


    handleChange = (event, newValue) => {
        this.setState({ value: newValue });
    };

    handleChangeIndex = (index) => {
        this.setState({ value: index });
    };

    onOpenModal = () => {
        this.setState({ open: true });
        this.getAllCMS();
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };



    getAllCMS = async () => {
        this.setState({ loading: true })
        const APIResponse = await GetMedia();
        console.log("GetCustomType MediaPopup", APIResponse);
        if (APIResponse !== null) {
            const filteredDates = APIResponse.data.data.sort((a, b) => new Date(b.created_on) - new Date(a.created_on))
            console.log("filteredDates", filteredDates)
            this.setState({ tableData: filteredDates, loading: true })
            // this.setState({ tableData: APIResponse.data.data, loading: true })
        }
    }

    selectFile = async (path) => {
        // alert(path)
        console.log("path", path)
        this.props.onSelectChange(path);
        this.setState({ open: false });
    }


    add = async () => {


        const formData = new FormData();
        this.pond.getFiles()
            .map(fileItem => fileItem.file)
            .forEach(file => {
                formData.append("file", file, file.name)
            });

        console.log(JSON.stringify(formData));
        const APIResponse = await CreateMedia(formData);
        console.log("CreateMedia", APIResponse);
        if (APIResponse !== null) {

            if (APIResponse.data.isSuccess !== false) {

                const message = APIResponse.data.messages;
                this.props.enqueueSnackbar(message, {
                    variant: 'info',
                    autoHideDuration: 3000,
                });

                this.setState({ value: 0, files: [] })
                this.getAllCMS();

            } else {
                const message = APIResponse.data.messages;
                this.props.enqueueSnackbar(message, {
                    variant: 'error',
                    autoHideDuration: 3000,
                });
            }

        } else {
            const message = "something went wrong, please try again";
            this.props.enqueueSnackbar(message, {
                variant: 'error',
                autoHideDuration: 3000,
            });
        }

    }

    render() {
        const { open } = this.state;
        return (
            <div className="MediaPopup">
                <Button variant="outlined" size="size" className="display-block" disabled={this.props.disabled === 'view' ? true : ''} color="secondary" onClick={this.onOpenModal}> {this.props.title} </Button>
                <Modal open={open} onClose={this.onCloseModal}>
                    <div className="media_popup container-fluid">
                        <h5>Media Management</h5>
                        <div className={''}>
                            {/* <AppBar position="static" color="default">
                                <Tabs
                                    value={this.state.value}
                                    onChange={this.handleChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="fullWidth"
                                    aria-label="full width tabs example"
                                >
                                    <Tab label="Select Files" {...a11yProps(0)} />
                                    <Tab label="Upload Files" {...a11yProps(1)} />
                                </Tabs>
                            </AppBar> */}
                            <SwipeableViews
                                axis={this.state.theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                index={this.state.value}
                                onChangeIndex={this.handleChangeIndex}
                            >
                                <TabPanel value={this.state.value} index={0} dir={this.state.theme.direction}>
                                    <div className="row" id="image_gallery">
                                        <React.Fragment>
                                            {this.state.tableData.map((item) => {
                                                return (
                                                    <React.Fragment>
                                                        <div className="col-md-3 mb-3 text-center">
                                                            <div className="image_gallery_item" onClick={() => this.selectFile(item.path)}>
                                                                <LazyLoadImage
                                                                    alt={item.title}
                                                                    effect="blur"
                                                                    src={process.env.react_app_base_url + "/" + item.path}
                                                                    title={item.title} />
                                                                <p className="title">{item.title}</p>
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                );
                                            })
                                            }
                                        </React.Fragment>
                                    </div>
                                    <Button variant="outlined" size="size" className="float-right" color="secondary" onClick={this.onCloseModal}> Confirm </Button>
                                </TabPanel>



                                <TabPanel value={this.state.value} index={1} dir={this.state.theme.direction}>

                                    <div className="row">
                                        <div className="col-md-12 mb-3">
                                            <FilePond
                                                allowMultiple={true}
                                                ref={(ref) => (this.pond = ref)}
                                                oninit={() => this.handleInit()}
                                                files={this.state.files}
                                                onupdatefiles={(fileItems) => {
                                                    this.setState({
                                                        files: fileItems.map((fileItem) => fileItem.file),
                                                    });
                                                }}
                                                name={"file"}
                                                labelIdle='<b>Drag your documents here or <span class="filepond--label-action">Browse</span></b>'
                                            />
                                        </div>

                                        <div className="clearfix"></div>
                                        <div className="col-md-12 mb-3">
                                            <Button variant="contained" size="large" className="float-right" color="primary" onClick={this.add} > Submit </Button>
                                        </div>
                                    </div>

                                </TabPanel>

                            </SwipeableViews>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default trackWindowScroll(MediaPopup);
