import React from "react";

interface CardProps {
  title?: string | null;
  description?: string | null;
  children?: React.ReactNode;
}

const Card = ({ title, description, children }: CardProps): JSX.Element => {
  return (
    <>
      <div className="card">
        <div className="card-body">
          {title && <h4 className="card-title">{title}</h4>}
          {description && <p className="card-title-desc">{description}</p>}
          {children && <div className="">{children}</div>}
        </div>
      </div>
    </>
  );
};

export default Card;
