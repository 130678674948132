/* eslint-disable no-empty-pattern */
import Wrapper from "../Wrapper";
import { useNavigate, useParams } from "react-router-dom";
import {
  ServiceDogList,
  DogBreadOption,
  GenderOption,
  AgeOption,
  dog_temperament,
  TimeSlotPreference,
  DogWalkerServices,
  TrainingRequirement,
  VaccineStatus,
  ApartmentType,
  ServiceType,
  groomers_service_type,
  ServicesOption,
  PaymentModeOption,
} from "../../components/utility";
import { useEffect, useState } from "react";
import { Button, Input, Select, Toggle } from "../../Library/Module";
import { Item } from "./Item";
import { CategoryBySubCategory, CMSBySlug } from "../../servies/services";
import { toast } from "react-toastify";
import Loader from "../../Library/Loader/Loader";
import { localStorageParse } from "../../Context/ThemeContext";

interface StateData {
  [x: string]: string;
}
type formDataProps = {
  title: string;
  slug: string;
  content: string;
  groomers_service_type: string;
};

const ServicesDetails = () => {
  const navigate = useNavigate();


  let { id, type, action } = useParams();
  const [isLoading, setIsLoading]: any = useState(false);
  const [data, setData]: any = useState([]);
  const [isError, setIsError] = useState(false);


  useEffect(() => {
    const pet_prefernce = localStorageParse('pet_prefernce')
    if (pet_prefernce) {
      console.log("pet_prefernce", JSON.parse(pet_prefernce)?.pet)
      const parse = JSON.parse(pet_prefernce)
      console.log("parse", parse?.pet)
      setIsLoading(false);
      const getData = async () => {
        const APIResponse = await CategoryBySubCategory(parse?.pet);
        if (APIResponse?.data?.isSuccess === true) {
          setData(APIResponse?.data?.data);
          setIsLoading(true);
        } else {
          toast.error("Something went wrong, please try again");
          setIsError(true);
          setIsLoading(true);
        }
      };
      getData();
    }
  }, []);

  return (
    <Wrapper>
      <div className="">
        <div className="pageTitle">
          <div className="container">
            <div className="row align-items-center">
              <div className="col">
              <div className="innerPageTitle">
                <h1>{"Our Services"}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="search_results">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12">
                <h2>Our Services</h2>
              </div>
            </div>
            <div className="row">
              {data && data?.map((item: any) => {
                return <Item data={item}></Item>;
              })}
              {!isLoading &&
                <table>
                  <tbody>
                    <Loader row={10} columns={3}></Loader>
                  </tbody>
                </table>
              }
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
export default ServicesDetails;
