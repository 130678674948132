import Placeholder from "../../images/placeholder.png";
import CustomTooltip from "../../Library/Tooltip/Tippy";
import Icon from "../../images/icon.png";
import { Button, Select } from "../../Library/Module";
import { useNavigate } from "react-router-dom";
import { localStorageParse } from "../../Context/ThemeContext";

export const Item = ({ data }: { data: any }) => {
  const navigate = useNavigate();
  const pet_prefernce = localStorageParse('pet_prefernce')
  const parse = pet_prefernce && JSON.parse(pet_prefernce)
  console.log("parse", parse?.pet)
  const ReadMore = (URL: any) => {
    navigate(`/service-details/${parse?.pet}/${URL}`);
  };
  const content =
    data?.short_description?.length > 180
      ? data?.short_description?.slice(0, 180) + "..."
      : data?.short_description;

  return (
    <div className="col-md-4 mb-4">
      <div className="inner">
        <div className="image text-center">
          <img src={process.env.react_app_base_url + "/" + data?.image} alt="" title="" />
        </div>
        <div className="content text-center">
          <h3>{data.title}</h3>
          <div className="serviceContent">
            <div dangerouslySetInnerHTML={{ __html: content }} />
          </div>
        </div>
        <div className="footer2 text-center">
          <Button
            buttonSize="lg"
            onClick={() => ReadMore(data?.slug)}
            buttonStyleRounded
            buttonStyleType="primary"
            buttonStyleOutline
            buttonText={"Read More >"}
          ></Button>
        </div>
      </div>
    </div>
  );
};
