import React, { useContext, useEffect, useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import CatIcon from '../images/Icon/Cat.png'
import DogIcon from '../images/Icon/Dog.png'
import { localStorageParse } from "../Context/ThemeContext";


const PetPreference = (): JSX.Element => {
  const [open, setOpen] = useState(false);
  const onCloseModal = () => setOpen(false);
  const choice = (data: string) => {
    console.log("data", data)
    const abc = { "pet": data }
    localStorage.setItem('pet_prefernce', JSON.stringify(abc))

    console.log("data", data)
    const abcd = {"city":"pune"}
    localStorage.setItem('city_prefernce', JSON.stringify(abcd))


    setOpen(false);
    window.location.reload();
  };

  useEffect(() => {
    const pet_prefernce = localStorageParse('pet_prefernce')
    if (pet_prefernce) {
      setOpen(false)
    } else { setOpen(true) }
  }, [])

  const closeIcon = (
    <svg fill="currentColor" viewBox="0 0 20 20" width={28} height={28}>
      <path
        fillRule="evenodd"
        d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z"
        clipRule="evenodd"
      ></path>
    </svg>
  );


  return (
    <Modal
      open={open}
      onClose={onCloseModal}
      closeOnOverlayClick={false}
      closeIcon={' '}
      center>
      <div className="petsSelection">
        <h2>Welcome to PAWZWHISKERS</h2>
        <p> We are a platform for all your pet related requirements. If you are a Pet Parent, we can be your best friend. </p>
        <div className="choice">
          <div className="item" onClick={() => choice("dog")}>
            <div className="icon"><img src={DogIcon} title="" alt="" /></div>
            <div className="text">Click here for
              WHOOFERFUL PAWS  </div>
          </div>
          <div className="item" onClick={() => choice("cat")}>
            <div className="icon">
              <img src={CatIcon} title="" alt="" />
            </div>
            <div className="text">Click here for
              PURRFECT WHISKERS </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default PetPreference;
