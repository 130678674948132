import Placeholder from "../../images/placeholder.png";
import CustomTooltip from "../../Library/Tooltip/Tippy";
import Icon from "../../images/icon.png";

import Address from "../../images/Icon/Address.png";
import Rating from "../../images/Icon/Icon/Rating.png";
import Address2 from "../../images/Address.png";
import Timeing from "../../images/Icon/Timeing.png";
import Cash from "../../images/Icon/Cash.png";
import YearsOFExp from "../../images/Icon/Years OF Exp.png";
import Payment from "../../images/Icon/Payment.png";

import { Button, Select, SideDrawer, Dash, Input } from "../../Library/Module";
import { useCallback, useEffect, useState } from "react";
import { SubmissionDetails, Vendors } from "../../servies/services";
import { useParams } from "react-router-dom";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../Library/Loader/Loader";
import { getTemplatedMessageInput, sendMessage } from "../../servies/axios";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";

type actionTypeState = {
  id?: any;
  type?: any;
  data?: any;
};

export const Item = ({
  pet_category,
  service_category,
  city,
  pageTitle,
  userLogged

}: {
  pet_category: any,
  service_category: any
  city: any
  pageTitle: string
  userLogged: any

}) => {
  let navigate = useNavigate()
  let { id, type, categry, serviceElement } = useParams();
  const [isLoading, setIsLoading]: any = useState(false);
  const [data, setData]: any = useState([]);
  const [isError, setIsError] = useState(false);
  const [listData, setListData]: any = useState([]);
  const [actionType, setActionType] = useState<actionTypeState>({});
  const frontendUserProfile = localStorage.getItem("frontendUserProfile")
  // const frontendUserDetails = frontendUserProfile && JSON.parse(frontendUserProfile)
  const [frontendUserDetails, setFrontendUserDetails] = useState<any>(frontendUserProfile && JSON.parse(frontendUserProfile))
  const userData = localStorage.getItem(pet_category + "_" + service_category)
  const userDetails = userData && JSON.parse(userData)

  const onChangeSingleCallback = (data: any) => {
    setFrontendUserDetails((prevState: any) => ({
      ...prevState,
      ...data,
    }));
  };

  useEffect(() => {
    const getData = async () => {
      let query = `?pet_category=${pet_category}&status=Active&service_category=${service_category}&city=${city}`
      if (userDetails?.area !== 'any') {
        query = `?pet_category=${pet_category}&status=Active&service_category=${service_category}&area=any&city=${city}&area=${userDetails?.area}`
      }

      const APIResponse = await Vendors(query);
      if (APIResponse?.data?.isSuccess === true) {
        setData(APIResponse?.data?.data);
        setIsLoading(true);
      } else {
        toast.error("Something went wrong, please try again");
        setIsError(true);
        setIsLoading(true);
      }
    };

    getData();

    if (userLogged) {
      setIsLoading(false);
      getData();
    } else {
      ConfirmLogin()
    }

  }, [city, pet_category, service_category, userDetails?.area, userLogged]);

  const ConfirmLogin = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <h1>Login | Register</h1>
            <p>Please login to you already have a register account.</p>
            <Button buttonStyleOutline buttonStyleType="primary" onClick={() => {
              onClose();
              navigate('/login')
            }}> Login </Button>
            <p>Please register with us to view the search results.</p>
            <Button buttonStyleOutline buttonStyleType="primary" onClick={() => {
              onClose();
              navigate('/register')
            }}> Register </Button>
          </div>
        );
      }
    });
  }

  const CloseDrawer = () => {
    setActionType({});
  };

  const emailNow = (data: any) => {
    setActionType({
      type: "add",
      id: id,
      data: data
    })
  };



  const Submit = async (type: string) => {
    if (frontendUserDetails?.first_name && frontendUserDetails?.last_name && frontendUserDetails?.email_id && frontendUserDetails?.mobile) {
      const data = {
        name: frontendUserDetails?.first_name + " " + frontendUserDetails?.last_name,
        email_id: frontendUserDetails?.email_id,
        mobile: frontendUserDetails?.mobile,
        city: city,
        area: userDetails?.area,
        pet: pet_category,
        service: service_category,
        enquiry_type: type,
        details: userData,
        status: "1",
        created_by: frontendUserDetails?.email_id,
        vendor_email_id: frontendUserDetails?.email_id,
        vendor_name: frontendUserDetails?.email_id,
        vendor_details: JSON.stringify(actionType?.data),
      }
      const APIResponse = await SubmissionDetails(data);
      if (APIResponse?.data?.isSuccess === true) {
        toast.success('Your enquiry has been submitted successfully. Our team will get back to you on prefered data and time.', {
          position: toast.POSITION.TOP_CENTER
        });

        setActionType({});
      } else {
        toast.error("Something went wrong, please try again");
        setIsError(true);
        setIsLoading(true);
      }
    } else {
      toast.error("Please enter required fields");
    }

    // const whatsAppData = getTemplatedMessageInput('919665330294', data, frontendUserDetails?.first_name);

    // sendMessage(whatsAppData)
    //   .then(function (response) {
    //     console.log("response", response)
    //     return;
    //   })
    //   .catch(function (error) {
    //     console.log("error", error);
    //     return;
    //   });
  }

  return (
    <>
      {/* {JSON.stringify(userLogged)} */}
      {userLogged && isLoading && data && data.map((item: any) => {
        return <div className="col-md-4 mb-4" id="searchResult">
          <div className="inner">
            <div className="image" style={{
              background: `url(${item?.profile_image && item?.profile_image?.length > 0 ?
                `${process.env.react_app_base_url}/${item?.profile_image}`
                :
                Placeholder}`
            }}>
            </div>

            <div className="content">
              <h3><Dash value={item?.title} /></h3>
              <p>
                <label>
                  <span className="icon">
                    <img src={Address} title="" alt="" />
                  </span>
                  Address
                </label>
                <Dash value={item?.address} />
              </p>
              <p>
                <label>
                  <span className="icon">
                    <img src={Timeing} title="" alt="" />
                  </span>
                  Timing
                </label>
                <Dash value={item?.opening_hours} />
              </p>
              <p>
                <label>
                  <span className="icon">
                    <img src={Cash} title="" alt="" />
                  </span>
                  Payment Mode
                </label>
                <Dash value={item?.payment_mode} />
              </p>
              <p>
                <label>
                  <span className="icon">
                    <img src={YearsOFExp} title="" alt="" />
                  </span>
                  Established Year
                </label>
                <Dash value={item?.establish_year} />
              </p>
              <p>
                <label>
                  <span className="icon">
                    <img src={Payment} title="" alt="" />
                  </span>
                  Minimum Charges
                </label>
                <Dash value={item?.min_charges} /> {item?.min_charges && 'Rs'}
              </p>

              <p>
                <label>
                  <span className="icon">
                    <img src={YearsOFExp} title="" alt="" />
                  </span>
                  Additional Info
                </label>
                <div className="about_vendor" dangerouslySetInnerHTML={{ __html: item?.about_vendor }} />
              </p>
            </div>
            <div className="footer2 ">
              <Button
                buttonSize="lg"
                onClick={() => emailNow(item)}
                buttonStyleRounded
                buttonStyleType="primary"
              >
                Enquire Now
              </Button>
            </div>
            <div className="clearfix"></div>
            {(item?.gallery?.length !== 0 && (item?.gallery !== undefined || item?.gallery !== null)) &&
              <div className="gallery">
                <ul>
                  <li><img src={`${process.env.react_app_base_url}/${item?.profile_image}`} alt="" title="" /></li>
                </ul>
                <div className="clearfix"></div>
              </div>
            }
          </div>
        </div>
      })}
      {userLogged && isLoading && data?.length === 0 && <div className="col-sm-12">
        <div className="noDataFound"> There are no {pageTitle} in the current query. </div></div>}
      {userLogged && !isLoading &&
        <table> <tbody> <Loader columns={3} row={5}></Loader></tbody>
        </table>
      }


      {(actionType?.type === "add" || actionType?.type === "edit") && (
        <SideDrawer
          size={'850px'}
          pagetitle={`Enquiry Details`}
          action={CloseDrawer}
        >
          <div className="innerMobile">
            <p className="withPadding"><strong>User Details</strong></p>
            {/* <ul className="list">
              <li><label>Name</label> {frontendUserDetails?.first_name + " " + frontendUserDetails?.last_name}</li>
              <li><label>Email Id</label> {frontendUserDetails?.email_id}</li>
              <li><label>Mobile No</label> {frontendUserDetails?.mobile}</li>
            </ul> */}
            <div className="container-fluid">
              <div className="row">
                <Input
                  inputName="first_name"
                  inputType="text"
                  labelName={"First name"}
                  placeholder="Enter First name"
                  required={true}
                  onChangeSingleCallback={onChangeSingleCallback}
                  value={frontendUserDetails?.first_name}
                  col="6"
                  isFormSubmitted={false}
                />
                <Input
                  inputName="last_name"
                  inputType="text"
                  labelName={"last name"}
                  placeholder="Enter Last name"
                  required={true}
                  onChangeSingleCallback={onChangeSingleCallback}
                  col="6"
                  isFormSubmitted={false}
                  value={frontendUserDetails?.last_name}
                />
                <Input
                  inputName="email_id"
                  inputType="text"
                  labelName={"email_id"}
                  placeholder="Enter email_id"
                  required={true}
                  onChangeSingleCallback={onChangeSingleCallback}
                  col="6"
                  isFormSubmitted={false}
                  value={frontendUserDetails?.email_id}
                />
                <Input
                  inputName="mobile"
                  inputType="text"
                  labelName={"mobile"}
                  placeholder="Enter mobile"
                  required={true}
                  onChangeSingleCallback={onChangeSingleCallback}
                  col="6"
                  isFormSubmitted={false}
                  value={frontendUserDetails?.mobile}
                />
              </div>
            </div>


            <div className="clearfix"></div>
            <p className="withPadding"><strong>Search Criteria</strong></p>
            <ul className="list">
              <li><label>City</label> Pune</li>
              <li><label>Pet</label> {pet_category}</li>
              <li><label>Category</label> {service_category}</li>
              {userDetails && Object.entries(userDetails)?.map((item) => {
                console.log("item", item)
                // return JSON.stringify(item)
                return <li><label>{item?.[0].replace(/_/g, " ")}</label> {item?.[1]}</li>
              })}
            </ul>
            <div className="clearfix"></div>
            <p className="withPadding"><strong>Vendor Details</strong></p>
            <ul className="list">
              <li><label>Vendor Name</label> {actionType?.data?.title}</li>

            </ul>
            <div className="clearfix"></div>
          </div>
          <div className="float-right footerPopup" style={{ paddingRight: '20px' }}>
            <Button className="" buttonStyleOutline buttonStyleType="info" onClick={() => Submit('email')} buttonText={'Email Details'}></Button>
            {actionType?.data?.whatsapp &&
              <a className="ml-3 btn waves-effect waves-light btn-md btn-outline-danger btn" target={'_blank'}
                onClick={() => Submit('whatsapp')}
                rel="noreferrer" href={
                  `https://api.whatsapp.com/send/?phone=${actionType?.data?.whatsapp}&text=${`*User Details*%0a Name : ${frontendUserDetails?.first_name + " " + frontendUserDetails?.last_name}%0a Email Id : ${frontendUserDetails?.email_id}%0a Mobile No : ${frontendUserDetails?.mobile}%0a *Search Criteria* %0a City : Pune %0a Pet : ${pet_category} %0a Category : ${service_category} %0a ${userDetails && Object.entries(userDetails)?.map((item) => {
                    const name = item?.[0]?.replace(/_/g, " ")
                    const value = item?.[1] ? item?.[0] === 'appointment_date' ? item?.[1] : item?.[1] === false ? 'Yes' : item?.[1] === true ? 'No' : item?.[1] : ''
                    return name?.charAt(0)?.toUpperCase() + name?.slice(1) + " : " + value + "%0a"
                  }).join('')} `}`}>Start Whatsapp Conversation</a>
            }
          </div>
        </SideDrawer>
      )}
    </>
  );
};
