import { Alert, Card, Input, Button } from "../../Library/Module";

const ClientRow = ({ rowData, index, showCells, callbackFresh }) => {
    const deleteRecord = () => {
        callbackFresh(rowData)
    }
    return (
        <tr key={index}>
            <td>{index + 1}</td>
            {showCells && showCells?.city && <td>{rowData.city}</td>}
            {showCells && showCells?.area && <td>{rowData.area}</td>}
            {showCells && showCells?.pet && <td>{rowData.pet}</td>}
            {showCells && showCells?.service && <td>{rowData.service}</td>}
            {showCells && showCells?.enquiry_type && <td>{rowData.enquiry_type} </td>}
            {showCells && showCells?.updated_on && <td>{rowData.updated_on}</td>}

            <td>
                <Button buttonStyleOutline buttonStyleType="danger" onClick={() => deleteRecord()} > View Details </Button>
            </td>
        </tr>
    );
};

export default ClientRow;
