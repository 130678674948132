/* eslint-disable no-empty-pattern */
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Wrapper from "../Wrapper";
import Loader from "../../Library/Loader/Loader";

const Login = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading]: any = useState(true);
    const [formData, setFormData] = useState<any>();
    const queryParams = new URLSearchParams(window.location.search)
    const email_token = queryParams.get("email_token")
    console.log("email_token", email_token)


    const Submit = async (email_token: string) => {
        setIsLoading(true);
        await axios
            .get(`https://pawzwhiskers.com/nodeapi/api/v1/user/verify_email?email_token=${email_token}`,)
            .then((response) => {
                setIsLoading(false)
                setFormData("Email Id verified successfully")
            })
            .catch(function (error) {
                setIsLoading(false)
                console.log(error.response?.data?.messages)
                if (error.response?.data?.messages) {
                    setFormData("Email Id already verified")
                    toast.error(error.response?.data?.messages);
                } else {
                    toast.error("Something went wrong, please try again");
                }

            });
    };

    useEffect(() => {
        if (email_token) {
            Submit(email_token)
        } else {
            navigate("/");
        }
    }, [email_token])


    return (
        <Wrapper>
            <div className="login_admin">
                {isLoading ?
                    <Loader loaderType="pageLoader"></Loader>
                 : 
                <div className="noDataFound red">{formData}</div>
            }
            </div>
        </Wrapper>
    );
};
export default Login;
