import moment from 'moment';
import React, { Component, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
interface InputProps {
  inputText?: string | null;
  inputType?: "text" | "number" | "email" | "password" | "url" | "textarea";
  className?: string;
  icon?: string;
  children?: any;
  placeholder?: string;
  inputName: string;
  labelName?: string;
  inputSubType?: "incrementer";
  id?: string;
  value?: string | number | undefined;
  required?: boolean;
  min?: number;
  max?: number;
  maxLength?: number;
  minLength?: number;
  disabled?: boolean;
  customValidationMsg?: string;
  onChangeCallback?: any;
  onChangeSingleCallback?: any;
  suffix?: string;
  col?: "1" | "2" | "3" | "4" | "5" | "6" | "9" | "10" | "11" | "12";
  isFormSubmitted?: boolean;
  readonly?: boolean;
}
const DatePickerComp = ({
  inputText = null,
  className,
  children,
  icon,
  placeholder = "",
  inputName,
  labelName,
  id,
  value,
  required = false,
  min,
  max,
  maxLength,
  minLength,
  disabled,
  customValidationMsg,
  onChangeCallback,
  onChangeSingleCallback,
  suffix,
  col = "12",
  inputSubType,
  isFormSubmitted = false,
  readonly = false,
}: InputProps): JSX.Element => {
  const [startDate, setStartDate] = useState(new Date())

  const handleChange = (date: any) => {
    let inputData = {
      key: inputName,
      value: date,
    };
    let single = { [inputName]: date };
    setStartDate(date)
    onChangeCallback?.(inputData);
    onChangeSingleCallback?.(single);
  }

  useEffect(() => {
    if (value) {
      setStartDate(value as any);
    } else {
      setStartDate(new Date());
    }
  }, [value]);

  console.log("moment(new Date()).add(2, 'M')", moment(new Date()).add(2, 'M'))
  const futureDate = (moment(new Date()).add(2, 'M')).toString()
  return (
    <>
      <div className={`mb-3 col-md-${col}`}>
        {labelName && (
          <label>
            {labelName} {required ? <span className="red">*</span> : ""}
          </label>
        )}
        <div
          className={`position-relative input-group ${className}`}
        >
          <DatePicker
            selected={startDate}
            onChange={handleChange}
            timeIntervals={20}
            dateFormat="dd/MM/yyyy"
            disabled={disabled}
            readOnly={readonly}
            minDate={new Date()}
            maxDate={new Date(futureDate)}

          />
          {icon && <span className={`icon ${icon}`}></span>}
        </div>

        {children && children}
      </div>
    </>
  )

}
export default DatePickerComp;

// class App extends Component {
//   constructor(props) {
//     super(props)
//     this.state = {
//       startDate: new Date()
//     };
//     this.handleChange = this.handleChange.bind(this);
//     this.onFormSubmit = this.onFormSubmit.bind(this);
//   }
//   handleChange(date) {
//     this.setState({
//       startDate: date
//     })
//   }
//   onFormSubmit(e) {
//     e.preventDefault();
//     console.log(this.state.startDate)
//   }

//   render() {
//     return (
//       <div className="mb-3 col-md-3"><label>Allergies <span className="red">*</span></label><div className="position-relative input-group  "></div>
//         <div onClick={() => this.onFormSubmit} className="form-group">
//           <DatePicker
//             selected={this.state.startDate}
//             onChange={this.handleChange}
//             timeIntervals={20}
//             dateFormat="dd/MM/yyyy"
//           />
//         </div></div>
//     )
//   }

// }
// export default App;