/* eslint-disable no-empty-pattern */
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Empty from "../../Library/Empty/Empty";
import Loader from "../../Library/Loader/Loader";
import { Card } from "../../Library/Module";
import { CMSBySlug, CMSByType } from "../../servies/services";
import Wrapper from "../Wrapper";
import Placeholder from "../../images/placeholder.png";

const ContactUs = () => {
  let { id, type, action } = useParams();
  const [isLoading, setIsLoading]: any = useState(false);
  const [data, setData]: any = useState(false);
  const [pageData, setPageData]: any = useState(null);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setIsLoading(false);
    const getData = async () => {
      const APIResponse = await CMSByType('pet-friendly-places');
      if (APIResponse?.data?.isSuccess === true) {
        setData(APIResponse?.data?.data);
        setIsLoading(true);
      } else {
        toast.error("Something went wrong, please try again");
        setIsError(true);
        setIsLoading(true);
      }
    };
    getData();
  }, []);


  useEffect(() => {
    setIsLoading(false);
    const getData = async () => {
      const APIResponse = await CMSBySlug('pet-friendly-places');
      if (APIResponse?.data?.isSuccess === true) {
        setPageData(APIResponse?.data?.data);
        setIsLoading(true);
      } else {
        toast.error("Something went wrong, please try again");
        setIsError(true);
        setIsLoading(true);
      }
    };
    getData();
  }, []);

  return (
    <Wrapper>
      {isLoading &&
        <>
          <div className="pageTitle">
            <div className="container">
              <div className="row align-items-center">
                <div className="col">
                  <div className="innerPageTitle">
                    <h1>Pet Friendly Places</h1>
                    <div dangerouslySetInnerHTML={{ __html: pageData?.description }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="petFriendlyPlaces mt-5 mb-5">
            <div className="container">
              <div className="row">
                {data && data?.length === 0 ?
                  <Empty
                    primaryText="No Pet Friendly Places found"
                    secondaryText="No Pet Friendly Places found" />
                  : ''}
                {data && data.map((item: any) => {
                  return (
                    <>
                      <div className="col-sm-4 col-md-4 mb-4">
                        <Card title={item?.title}>
                          <div className="row">
                            <div className="col-sm-12 col-md-12">
                              <img src={item?.image && item?.image?.length > 0 ?
                                `${process.env.react_app_base_url}/${item?.image}`
                                :
                                Placeholder} alt="" title="" />
                            </div>
                            <div className="col-sm-12 col-md-12">
                              <div className="p-3 pb-0">
                                <div dangerouslySetInnerHTML={{ __html: item?.description }} />
                              </div>
                            </div>

                          </div>
                        </Card>
                      </div>
                    </>
                  )
                })}
              </div>
            </div>
          </div>
        </>
      }
      {!isLoading && <Loader loaderType={'pageLoader'}></Loader>}
    </Wrapper >
  );
};
export default ContactUs;
