import {
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom';
import './css/bootstrap.css';
import './css/style.css';
import 'react-toastify/dist/ReactToastify.css';
import 'font-awesome/css/font-awesome.css';
import { ToastContainer } from 'react-toastify';
import { setUpAxios } from './servies/services';
import Login from './container/Login/Login';
import VerifyEmail from './container/VerifyEmail/VerifyEmail';
import ForgotPassword from './container/ForgotPassword/ForgotPassword';
import ChangePassword from './container/ChangePassword/ChangePassword';
import Dashboard from './container/Dashboard/Dashboard';
import MyEnquiry from './container/Dashboard/MyEnquiry'
import UpdateProfile from './container/Dashboard/UpdateProfile'
import AfterChangePassword from './container/Dashboard/ChangePassword'

import Home from './container/Home/Home';
import PageNotFound from './container/PageNotFound/PageNotFound';
import Register from './container/Register/Register';
import ContactUs from './container/ContactUs/ContactUs';
import Services from './container/Services/Services';
import AboutUs from './container/AboutUs/AboutUs';
import ServicesDetails from './container/ServicesDetails/ServicesDetails';
import UpdateVendorDetails from './container/Dashboard/UpdateVendorDetails';
import PetFriendlyPlaces from './container/PetFriendlyPlaces/PetFriendlyPlaces';



function App() {
  setUpAxios();
  return (
    <>
      <BrowserRouter>
        <Routes >
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/verify_email" element={<VerifyEmail />} />
          <Route path="/register" element={<Register />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/pet-friendly-places" element={<PetFriendlyPlaces />} />
          <Route path="/services" element={<Services />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/service-details/:categry/:serviceElement" element={<ServicesDetails />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/change-password" element={<ChangePassword />} />
          <Route path="/my-pawzwhiskers-space/dashboard" element={<Dashboard />} />
          <Route path="/my-pawzwhiskers-space/my-enquiry" element={<MyEnquiry />} />
          <Route path="/my-pawzwhiskers-space/update-profile" element={<UpdateProfile />} />
          <Route path="/my-pawzwhiskers-space/change-password" element={<AfterChangePassword />} />
          <Route path="/my-pawzwhiskers-space/update-dog-foster-homes" element={<UpdateVendorDetails />} />

          <Route path="*" element={<PageNotFound />} />
        </Routes>
        <ToastContainer
          position="top-center"
          newestOnTop={false}
          autoClose={10000}
        ></ToastContainer>
      </BrowserRouter>
    </>
  );
}

export default App