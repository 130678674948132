/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import EnquiryRow from "./EnquiryRow";
import { Button, Card, CustomTable, SideDrawer } from "../../Library/Module";
import Wrapper from "../Wrapper";
import UserNav from "./UserNav";
import { GetSubmissionByUserId } from "../../servies/services";

const tableConfig = {
    search: {
        name: "id",
        isAsc: false,
    },
    sortDefault: {
        name: "id",
        isAsc: false,
    },

    tableHeader: [
        {
            name: "id",
            display: "Id",
            sort: true,
            sortType: "number",
        },
        {
            name: "city",
            display: "city",
            sort: true,
        },
        {
            name: "area",
            display: "area",
            sort: true,
        },
        {
            name: "pet",
            display: "pet",
        },
        {
            name: "category",
            display: "category",
            sort: true,
        },
        {
            name: "enquiry_type",
            display: "enquiry_type",
            sortType: "number",
        },

        {
            name: "updated_on",
            display: "updated_on",
            sort: true,
        },
        {
            name: "_id",
            display: "Action"
        },
    ],
    dropdown: [
        {
            name: "gender",
            value: ["Female", "Male"],
        },
        {
            name: "city",
            value: ["Pune", "Murud", "Latur"],
        },
    ],
};
type actionTypeState = {
    id?: any;
    type?: any;
    data?: any;
};
const MyEnquiries = () => {
    const [responseData, setResponseData]: any = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showCells, setShowCells] = useState(null);
    const [sortState, setSortState] = useState(tableConfig.sortDefault);
    const [actionType, setActionType] = useState<actionTypeState>({});

    const showCellCallback = (data: any) => {
        setShowCells(data);
    };
    const frontendUserData = localStorage.getItem('frontendUserData')
    const userData = frontendUserData && JSON.parse(frontendUserData)

    useEffect(() => {
        setIsLoading(false);
        const getData = async () => {
            const APIResponse = await GetSubmissionByUserId(userData?.email_id);
            if (APIResponse?.data?.isSuccess === true) {
                setResponseData(APIResponse?.data?.data);
                setIsLoading(true);
            } else {
                toast.error("Something went wrong, please try again");
                setIsLoading(true);
            }
        };
        getData();
    }, []);

    const CloseDrawer = () => {
        setActionType({});
    };

    const callbackFresh = (data: any) => {
        console.log('data', data)
        setActionType({
            type: "add",
            id: 'id',
            data: data
        })
    }

    return (
        <Wrapper>
            <UserNav></UserNav>
            <div className="mySpace">
                <div className="container">
                    <div className="row">
                        <Card title={'My Enquires'}>
                            <CustomTable
                                header={tableConfig.tableHeader}
                                sortDefault={tableConfig.sortDefault}
                                data={responseData}
                                tableTitle={" "}
                                dropdown={''}
                                search_bar={false}
                                showCellCallback={showCellCallback}
                                sortState={sortState}
                                isLoading={isLoading}
                            >
                                {({ rowData, index }: { rowData: any; index: any }) => (
                                    <EnquiryRow
                                        rowData={rowData}
                                        index={index}
                                        showCells={showCells}
                                        callbackFresh={callbackFresh}
                                    ></EnquiryRow>
                                )}
                            </CustomTable>
                        </Card>
                    </div></div></div>

            {(actionType?.type === "add" || actionType?.type === "edit") && (
                <SideDrawer
                    size={'850px'}
                    pagetitle={`Enquiry Details`}
                    action={CloseDrawer}
                >
                    <div className="clearfix"></div>
                    <p className="withPadding"><strong>Search Criteria</strong></p>
                    <ul className="list">
                        <li><label>City</label> {actionType?.data?.city}</li>
                        <li><label>Area</label> {actionType?.data?.area}</li>
                        <li><label>Pet</label> {actionType?.data?.pet}</li>
                        <li><label>Category</label> {actionType?.data?.service}</li>
                        <li><label>Enquiry Type</label> {actionType?.data?.enquiry_type}</li>
                        {actionType?.data?.details && Object.entries(JSON.parse(actionType?.data?.details))?.map(([key, value]) => {
                            return <>

                                <li><label>{key.replace(/_/g, " ")}</label> {value}</li>
                            </>
                        })}
                    </ul>
                    <div className="clearfix"></div>
                    <p className="withPadding"><strong>Vendor Details</strong></p>
                    <ul className="list">
                        {actionType?.data?.vendor_details && Object.entries(JSON.parse(actionType?.data?.vendor_details))?.map(([key, value]) => {
                            return <> {['title', 'email_id', 'mobile', 'address', 'city', 'area'].includes(key) ? <li><label>{key.replace(/_/g, " ")}</label> {value}</li> : ''}
                            </>
                        })}
                    </ul>
                    <div className="clearfix"></div>
                </SideDrawer>
            )}
        </Wrapper>
    );
}
export default MyEnquiries;
