/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import EnquiryRow from "./EnquiryRow";
import { Alert, Button, Card, CustomTable, Input, Select, Toggle } from "../../Library/Module";
import Wrapper from "../Wrapper";
import UserNav from "./UserNav";
import { RegisterUser, UserById } from "../../servies/services";

type formDataProps = {
    email_id: string;
    password: string;
    dog?: boolean;
    foster_parent?: boolean;
    first_name: string;
    last_name: string;
    mobile: string;
    status: string;
    address: string;
    details?: any
};
const MyEnquiries = () => {
    const navigate = useNavigate();
    const [isError, setIsError]: any = useState(false);
    const [isFormSubmitted, setIsFormSubmitted]: any = useState(false);
    const [isValid, setIsValid]: any = useState(true);
    const [formData, setFormData] = useState<formDataProps>();

    const onChangeSingleCallback = (data: any) => {
        setFormData((prevState) => ({
            ...prevState,
            ...data,
        }));
        setIsFormSubmitted(false);
    };

    const Submit = async (event: any) => {
        setIsFormSubmitted(true);
        if (
            formData?.email_id !== undefined &&
            formData?.email_id.length !== 0 &&
            formData?.password !== undefined &&
            formData?.password.length !== 0
        ) {
            setIsValid(true);
            const postData = {
                email_id: formData?.email_id.toLowerCase(),
                password: formData?.password,
                user_type: 'customer',
                first_name: formData?.first_name,
                last_name: formData?.last_name,
                mobile: formData?.mobile,
                status: "0",
            };

            const APIResponse = await RegisterUser(postData);
            if (APIResponse?.data?.isSuccess === true) {
                toast.success(APIResponse.data.messages);
                navigate("/login");
            } else {
                toast.error("Something went wrong, please try again");
            }


        } else {
            setIsValid(false);
            toast.error("Please enter required fields");
        }
    };

    const frontendUserData = localStorage.getItem('frontendUserData')
    const userData = frontendUserData && JSON.parse(frontendUserData)

    useEffect(() => {
        const getData = async () => {
            const APIResponse = await UserById(userData?.id);
            if (APIResponse?.data?.isSuccess === true) {
                console.log("UserById", APIResponse.data?.data)
                setFormData(APIResponse.data?.data)
                // navigate("/");
                // toast.success(APIResponse.data.messages);
                // localStorage.setItem('frontendUserProfile', JSON.stringify(APIResponse.data?.data))
            } else {
                toast.error("Something went wrong, please try again");
                setIsError(true);

            }
        };
        getData();
    }, [])


    return (
        <Wrapper>
            <UserNav></UserNav>
            <div className="mySpace">
                <div className="container">
                    <div className="row">
                        <Card title={'Update Profile'}>
                            <p className="mb-4"> You can update the password in this section. </p>
                            {isError && (
                                <Alert
                                    AlertStyleType="danger"
                                    AlertText={`some error msg here`}
                                ></Alert>
                            )}
                            {!isValid && (
                                <Alert
                                    AlertStyleType="danger"
                                    AlertText={`Please enter required fields`}
                                ></Alert>
                            )}
                            <div className="row">
                                <Input
                                    inputName="first_name"
                                    inputType="text"
                                    labelName={"First Name"}
                                    placeholder="Enter First Name"
                                    required={true}
                                    onChangeSingleCallback={onChangeSingleCallback}
                                    col="6"
                                    isFormSubmitted={isFormSubmitted}
                                    value={formData?.first_name}
                                />
                                <Input
                                    inputName="last_name"
                                    inputType="text"
                                    labelName={"Last Name"}
                                    placeholder="Enter Last Name"
                                    required={true}
                                    onChangeSingleCallback={onChangeSingleCallback}
                                    col="6"
                                    isFormSubmitted={isFormSubmitted}
                                    value={formData?.last_name}
                                />
                                <Input
                                    inputName="mobile"
                                    inputType="text"
                                    labelName={"mobile"}
                                    placeholder="Enter mobile"
                                    required={true}
                                    onChangeSingleCallback={onChangeSingleCallback}
                                    col="6"
                                    isFormSubmitted={isFormSubmitted}
                                    value={formData?.mobile}
                                />
                                <Input
                                    inputName="email_id"
                                    inputType="text"
                                    labelName={"Email Id"}
                                    placeholder="Enter email_id"
                                    required={true}
                                    onChangeSingleCallback={onChangeSingleCallback}
                                    col="6"
                                    isFormSubmitted={isFormSubmitted}
                                    value={formData?.email_id}
                                />

                                <Input
                                    inputName="address"
                                    inputType="text"
                                    labelName={"Address"}
                                    placeholder="Enter Address"
                                    required={true}
                                    onChangeSingleCallback={onChangeSingleCallback}
                                    col="6"
                                    isFormSubmitted={isFormSubmitted}
                                    value={formData?.address}
                                />
                                <Toggle
                                    required={true}
                                    col="12"
                                    onChangeSingleCallback={onChangeSingleCallback}
                                    inputName={"dog"}
                                    labelName="Dog"
                                ></Toggle>
                                {formData?.details?.dog !== undefined && formData?.details?.dog === false && (
                                    <>
                                        <Input
                                            inputName="dog_breed"
                                            inputType="text"
                                            labelName={"Breed"}
                                            placeholder="Enter Dog Breed"
                                            required={true}
                                            onChangeSingleCallback={onChangeSingleCallback}
                                            col="6"
                                            isFormSubmitted={isFormSubmitted}
                                            value={formData?.details?.dog_breed}
                                        />
                                        <Input
                                            inputName="age"
                                            inputType="number"
                                            labelName={"Age"}
                                            placeholder="Enter Age"
                                            required={true}
                                            onChangeSingleCallback={onChangeSingleCallback}
                                            col="6"
                                            isFormSubmitted={isFormSubmitted}
                                            value={formData?.details?.age}
                                        />
                                    </>
                                )}
                                <Toggle
                                    required={true}
                                    col="12"
                                    onChangeSingleCallback={onChangeSingleCallback}
                                    inputName={"foster_parent"}
                                    labelName="Interested in being Foster Parent"
                                    toggleValue={formData?.details?.foster_parent}
                                ></Toggle>
                                {formData?.details?.foster_parent !== undefined &&
                                    formData?.details?.foster_parent === false && (
                                        <>
                                            <Input
                                                inputName="experience_with_dogs"
                                                inputType="text"
                                                labelName={"Experience with Dogs"}
                                                placeholder="Experience with Dogs"
                                                required={true}
                                                onChangeSingleCallback={onChangeSingleCallback}
                                                col="6"
                                                isFormSubmitted={isFormSubmitted}
                                                value={formData?.details?.experience_with_dogs}
                                            />
                                            <Select
                                                inputName="dog_size_preference"
                                                labelName="Dog size preference"
                                                options={[
                                                    {
                                                        id: "1",
                                                        text: "Small",
                                                        value: "Small",
                                                    },
                                                    {
                                                        id: "2",
                                                        text: "Big",
                                                        value: "Big",
                                                    },
                                                ]}
                                                onChangeSingleCallback={onChangeSingleCallback}
                                                required={true}
                                                placeholder="Select Dog size preference"
                                                search_option={false}
                                                col="6"
                                                selectedItem={formData?.details?.experience_with_dogs}
                                            ></Select>
                                        </>
                                    )}
                            </div>
                            <p>Enter your Current Passowrd to update the profile</p>
                            <Input
                                inputName="password"
                                inputType="password"
                                labelName={"Password"}
                                placeholder="Enter password"
                                required={true}
                                onChangeSingleCallback={onChangeSingleCallback}
                                col="6"
                                isFormSubmitted={isFormSubmitted}
                            />
                            <div className="row align-content-end justify-content-end">
                                <div className="col-sm-6 text-left">

                                </div>
                                <div className="col-sm-6 text-right">
                                    <Button
                                        onClick={() => Submit("")}
                                        buttonText={"Update Profile"}
                                        buttonStyleType={"primary"}
                                    />

                                </div>
                            </div>
                        </Card>
                    </div></div></div>
        </Wrapper>
    );
}
export default MyEnquiries;
