/* eslint-disable no-empty-pattern */
import { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Stats from "../components/Stats";
import ScrollToTop from "../components/ScrollToTop";
import { useLocation } from "react-router-dom";
import PetPreference from "../components/PetPreference";
import { CategoryByCMSType, GetCityService, CategoryBySubCategory } from "../servies/services";
import { toast } from "react-toastify";
import { localStorageParse } from "../Context/ThemeContext";

interface AlertProps {
  children?: any;
  pageTitle?: string;
  breadcrumbList?: any;
  formDataCallback?: any;
  servicesCallback?: any;
  cityCallback?: any;
  subCategoryCallback?: any
}
interface formDataProps {
  pet_preference?: string;
}
const Wrapper = ({
  children,
  servicesCallback,
  cityCallback,
  subCategoryCallback
}: AlertProps): JSX.Element => {
  const path = useLocation();
  console.log("asdasd", path.pathname);
  const [customPostTypeCat, setCustomPostTypeCat] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);

  const [isCatLoading, setIsCatLoading] = useState(false);
  const [isCityLoading, setIsCityLoading] = useState(false);
  const [isSubCatLoading, setIsSubCatLoading] = useState(false);

  const [open, setOpen] = useState(false);
  const onCloseModal = () => setOpen(false);
  const choice = (data: string) => {
    localStorage.setItem("pet_preference", data);
    setOpen(false);
  };

  const pet_prefernce = localStorageParse('pet_prefernce')
  const parse = pet_prefernce && JSON.parse(pet_prefernce)
  console.log("Wrapper.tsx", parse?.pet)

  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const pet_preference = localStorage.getItem("pet_preference") || null;
    if (pet_preference === null) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, []);
  const [formData, setFormData] = useState<formDataProps>();
  const PetPreferenceCallback = (data: any) => {
    setFormData((prevState) => ({
      ...prevState,
      ...data,
    }));
  };

  useEffect(() => {
    if (formData) {
      console.log("formData", formData)
      localStorage.setItem("pawzwhiskers_preference", JSON.stringify(formData));
    }
  }, [formData]);

  useEffect(() => {
    setIsCatLoading(false);
    const getData = async () => {
      const APIResponse = await CategoryByCMSType('services');
      if (APIResponse?.data?.isSuccess === true) {
        console.log("APIResponse CategoryByCMSType", APIResponse?.data?.data)
        let updatedList: any = []
        APIResponse?.data?.data?.map((item: any) => {
          return updatedList.push({ text: item?.title, id: item?._id, value: item?.slug })
        })
        setCustomPostTypeCat(updatedList);
        servicesCallback?.({ data: updatedList, isloading: isCatLoading })
        setIsCatLoading(true);
      } else {
        toast.error("Something went wrong, please try again");

        setIsCatLoading(true);
      }
    };
    getData();
  }, [])

  useEffect(() => {
    setIsCityLoading(false);
    const getData = async () => {
      const APIResponse = await GetCityService();
      if (APIResponse?.data?.isSuccess === true) {
        console.log("APIResponse CategoryByCMSType", APIResponse?.data?.data)
        let updatedList: any = []
        APIResponse?.data?.data?.map((item: any) => {
          return updatedList.push({ text: item?.title, id: item?._id, value: item?.slug })
        })
        setCityList(updatedList);
        cityCallback?.({ data: updatedList, isloading: isCityLoading })
        setIsCityLoading(true);
      } else {
        toast.error("Something went wrong, please try again");
        setIsCityLoading(true);
      }
    };
    getData();

  }, [])

  useEffect(() => {
    const pet_prefernce = localStorageParse('pet_prefernce')
    if (pet_prefernce) {
      console.log("pet_prefernce", JSON.parse(pet_prefernce)?.pet)
      const parse = JSON.parse(pet_prefernce)
      console.log("parse", parse?.pet)
      setIsSubCatLoading(false);
      const getData = async () => {
        const APIResponse = await CategoryBySubCategory(parse?.pet);
        if (APIResponse?.data?.isSuccess === true) {
          setSubCategoryList(APIResponse?.data?.data);
          setIsSubCatLoading(true);
          subCategoryCallback?.({ data: APIResponse?.data?.data, isloading: isSubCatLoading })
        } else {
          toast.error("Something went wrong, please try again");
          setIsError(true);
          setIsSubCatLoading(true);
        }
      };
      getData();
    }
  }, []);

  return (
    <>
      <div className="pageWrapper">
        <ScrollToTop path={path.pathname} />
        <Header
          customPostTypeCat={customPostTypeCat}
          cityList={cityList}
          isCatLoading={isCatLoading}
          isCityLoading={isCityLoading}
        ></Header>
        {children}
        <Stats
          isSubCatLoading={isSubCatLoading}
          subCategoryList={subCategoryList}
        ></Stats>
        <Footer></Footer>
        <PetPreference></PetPreference>
      </div>
    </>
  );
};
export default Wrapper;
