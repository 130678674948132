import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AboutUsImage from "../images/aboutUs2.png";
import { Button } from "../Library/Module";
import { CMSBySlug } from "../servies/services";
import { ThemeContext } from "../Context/ThemeProvider";
const AboutUs = () => {
  const { theme, addTodo } = useContext(ThemeContext);
  const navigate = useNavigate();
  const ReadMoreAbout = () => {
    navigate("/about-us");
  };
  const [isLoading, setIsLoading]: any = useState(false);
  const [data, setData]: any = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setIsLoading(false);
    const getData = async () => {
      const APIResponse = await CMSBySlug('home');
      if (APIResponse?.data?.isSuccess === true) {
        setData(APIResponse?.data?.data);
        setIsLoading(true);
      } else {
        toast.error("Something went wrong, please try again");
        setIsError(true);
        setIsLoading(true);
      }
    };
    getData();
  }, []);

  return (
    <div className="aboutUs">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-sm-5 col-md-5 ">
            <div className="position-relative imageAboutUsPlaceholder">
              <div className="imageAboutUs"></div>
              <img src={AboutUsImage} title="" alt="" />
            </div>
          </div>
          <div className="col-sm-6 col-md-6">
            <div dangerouslySetInnerHTML={{ __html: data?.description }} />
            <Button
              onClick={() => ReadMoreAbout()}
              buttonStyleRounded
              buttonStyleType="primary"
              buttonSize="lg"
            >
              Read More
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AboutUs;
