/* eslint-disable no-empty-pattern */
import { useState } from "react";
import axios from "axios";
import { Alert, Card, Input, Button } from "../../Library/Module";
import { toast } from "react-toastify";
import { NavLink, useNavigate } from "react-router-dom";
import Wrapper from "../Wrapper";

type formDataProps = {
  email_id: string;
  password: string;
};

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [isFormSubmitted, setIsFormSubmitted]: any = useState(false);
  const [isValid, setIsValid]: any = useState(true);
  const [formData, setFormData] = useState<formDataProps>();

  const onChangeSingleCallback = (data: any) => {
    setFormData((prevState) => ({
      ...prevState,
      ...data,
    }));
    setIsFormSubmitted(false);
  };

  const Submit = async (event: any) => {
    setIsFormSubmitted(true);
    if (formData?.email_id !== undefined && formData?.email_id.length !== 0) {
      setIsValid(true);
      await axios
        .get(
          `https://pawzwhiskers.com/nodeapi/api/v1/user/resetpassword?email_id=${formData?.email_id}&user_type=customer`
        )
        .then((response) => {
          console.log("response", response);
          toast.success(response.data.messages);
        })
        .catch(function (error) {
          if (error.response?.data?.messages) {
            toast.error(error.response?.data?.messages);
          } else {
            toast.error("Something went wrong, please try again");
          }
        });
    } else {
      setIsValid(false);
      toast.error("Please enter required fields");
    }
  };


  return (
    <Wrapper>
      <div className="login_admin">
        <Card>
          <h3>Forgot Your Password</h3>
          <p className="text-center mb-4">
            Enter email id to continue to Pawzwhiskers
          </p>
          <div className="container-fluid">
            {!isValid && (
              <Alert
                AlertStyleType="danger"
                AlertText={`Please enter required fields`}
              ></Alert>
            )}
            <div className="row">
              <Input
                inputName="email_id"
                inputType="email"
                labelName={"Email Id"}
                placeholder="Enter email id"
                required={true}
                onChangeSingleCallback={onChangeSingleCallback}
                col="12"
                isFormSubmitted={isFormSubmitted}
              />
            </div>
            <div className="row align-content-end justify-content-end">
              <div className="col-sm-6 text-left">
              <NavLink to="/login">Login</NavLink>
              </div>
              <div className="col-sm-6 text-right">
                <Button
                  onClick={() => Submit("")}
                  buttonText={"Forgot Password"}
                  buttonStyleType={"primary"}
                />
              </div>
            </div>
          </div>
        </Card>
      </div>
    </Wrapper>
  );
};
export default ForgotPassword;
