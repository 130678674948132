/* eslint-disable no-empty-pattern */
import Wrapper from "../Wrapper";

const HomePage = () => {
  return (
    <Wrapper>
      <h3 className="pageNotFound">Page Not Found</h3>
    </Wrapper>
  );
};
export default HomePage;
