import { useEffect, useState } from "react";
import AboutUsImage from "../../images/aboutUs2.png";
import { NavLink, useNavigate } from "react-router-dom";
import { localStorageParse } from "../Context/ThemeContext";
import { VendorCatCount } from "../servies/services";
import { toast } from "react-toastify";
import { objectToQueryString } from "./utility";

type StatsProps = {
  isSubCatLoading?: any,
  subCategoryList?: any,
}

const Stats = ({
  isSubCatLoading,
  subCategoryList
}: StatsProps): JSX.Element => {

  localStorage.setItem("auth-token", "asdasdasdasdasdsd");
  const navigate = useNavigate();
  const pet_prefernce = localStorageParse('pet_prefernce')
  const parse = pet_prefernce && JSON.parse(pet_prefernce)
  console.log("parse", parse?.pet)
  const ServiesList = (URL: any) => {
    navigate(`/service-details/${parse?.pet}/${URL}`);
    // service-details/dog/find-a-vet
  };
  const [isSubCatLoading2, setIsSubCatLoading2] = useState(false);
  const [subCategoryList2, setSubCategoryList2] = useState([]);

  useEffect(() => {
    console.log("subCategoryList", subCategoryList)

    if (subCategoryList.length > 0) {
      const arr: any = []
      subCategoryList?.map((item: any) => {
        return arr.push(item.slug)
      });

      setIsSubCatLoading2(false);

      const getData = async () => {
        const city_prefernce = localStorageParse('city_prefernce') || null
        const pet_prefernce = localStorageParse('pet_prefernce') || null
        const parse = JSON.parse(city_prefernce as any)
        const pet = JSON.parse(pet_prefernce as any)
        console.log("parse city_prefernce", parse?.city)
        const query = {
          city: parse?.city,
          pet_category: pet?.pet
        }

        const data = {
          "pet_category": pet?.pet,
          "category": arr,
          "city": parse?.city
        }

        const APIResponse = await VendorCatCount(objectToQueryString(query), data);

        if (APIResponse?.data?.isSuccess === true) {
          console.log("APIResponse?.data?.data", APIResponse?.data)
          setSubCategoryList2(APIResponse?.data?.arr);
          setIsSubCatLoading2(true);
        } else {
          toast.error("Something went wrong, please try again");
          setIsSubCatLoading2(true);
        }
      };
      getData();
    }
  }, [subCategoryList]);

  return (
    <div className="stats">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-sm-12 col-md-12">
            <h1>
              <span>Stats</span>
            </h1>
          </div>
        </div>
        <div className="row">
          {subCategoryList?.map((item: any) => {
            return (
              <div
                className="col cursor-pointer"
                onClick={() => ServiesList(item.slug)}
              >
                <div className="inner">
                  <h2>
                    <span> {subCategoryList2[item.slug] ?? 0} </span>
                  </h2>
                  {item.title}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default Stats;
