import { useState } from "react";
import { Input, Button } from "../Library/Module";
import { NavLink, useNavigate } from "react-router-dom";
import Logo from "../images/logo_footer.png";
const Header = () => {
  return (
    <div className="footer">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-4">          
            <p className="logoFooter">
              <NavLink to={"/"}>
                <img src={Logo} title="" alt="" />
              </NavLink>
            </p>
          </div>
          <div className="col-sm-12 col-md-4">
            <h1>Main Menu </h1>
            <div className="footerNav">
              <ul>
                <li>
                  <NavLink to={"/"}> Home </NavLink>
                </li>
                <li>
                  <NavLink to={"/about-us"}> About Us </NavLink>
                </li>
                <li>
                  <NavLink to={"/services"}> Services </NavLink>
                </li>
                <li>
                  <NavLink to={"/contact-us"}> Contact Us </NavLink>
                </li>
                <li>
                  <NavLink to={"/login"}> Login </NavLink>
                </li>
                <li>
                  <NavLink to={"/register"}> Register </NavLink>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-sm-12 col-md-4">
            <h1>Join Our Newsletter </h1>
            <Input inputName="newsletter"></Input>
            <Button buttonStyleOutline buttonStyleType="white">
              Submit
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Header;
