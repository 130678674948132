import React, { useState } from "react";

interface DashProps {
    className?: string;
    value?: string
}

const Dash = ({
    className,
    value
}: DashProps): JSX.Element => {
    return (
        <>
            {value === undefined || value === null || value.length < 1 ?
                <span className={className ? className : 'black-color'}> -- </span> : value}
        </>
    );
};

export default Dash;
