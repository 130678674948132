/* eslint-disable no-empty-pattern */
import { NavLink, useNavigate } from "react-router-dom";
export const listData = [
    { "url": "/my-pawzwhiskers-space/dashboard", "title": "Dashboard", "slug": "dashboard", 'description': 'You can manage the dashboard in this moudle' },
    { "url": "/my-pawzwhiskers-space/my-enquiry", "title": "My Enquiry", "slug": "my-enquiry", 'description': 'You can manage the dashboard in this moudle' },
    { "url": "/my-pawzwhiskers-space/update-profile", "title": "Update Profile", "slug": "update-profile", 'description': 'You can update your profile in this moudle' },
    { "url": "/my-pawzwhiskers-space/change-password", "title": "Change Password", "slug": "change-password", 'description': 'You can change password in this moudle' }
]
const UserNav = () => {
    const navigate = useNavigate();
    const frontendUserProfile = localStorage.getItem("frontendUserProfile")
    const frontendUserDetails = frontendUserProfile && JSON.parse(frontendUserProfile)


    const ServiesList = (URL: any) => {
        navigate(`${URL}`);
    };

    return (
        <>
            <div className="subNav">
                <div className="container">
                    <div className="row align-items-center">
                        <ul className="userList">
                            {listData && listData?.map((item: any) => {
                                return (
                                    <li className="col cursor-pointer"><NavLink to={item?.url}>
                                        <p>{item?.title}</p>
                                    </NavLink>
                                    </li>
                                );
                            })}


                            {!frontendUserDetails?.details?.foster_parent &&
                                <li className="col cursor-pointer"><NavLink to={'/my-pawzwhiskers-space/update-dog-foster-homes'}>
                                    <p>Update Dog Foster Homes</p>
                                </NavLink>
                                </li>
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};
export default UserNav;
