import React, { useEffect, useState, useMemo } from "react";


interface TableDownloadProps {
  columns?: any;
  row?: any;
  loaderType?: any
}
const Loader = ({ columns, row = "5", loaderType = 'tableLoader' }: TableDownloadProps): JSX.Element => {
  return (
    <>
      {loaderType === 'pageLoader' ?
        <div className="pageLoaderWrapper">
          <div className="pageLoader"></div>
        </div>
        :
        Array.from(Array(columns).keys()).map((i) => {
          return (
            <tr className="loader">
              {Array.from(Array(columns).keys()).map((i) => {
                return (
                  <td>
                    <span></span>
                  </td>
                );
              })}
            </tr>
          );
        })}

    </>
  );
};

export default Loader;
