import React, { useState } from "react";
import { Card } from "../Module";
import notFoundIcon from './cloud-computing.png'
interface EmptyProps {
    className?: string;
    primaryText?: string
    secondaryText?: string
    icon?: string
}

const Empty = ({ className, primaryText, secondaryText, icon }: EmptyProps): JSX.Element => {
    return (
        <div className={className} style={{maxWidth:"500px", margin:"auto"}}>
            <Card>
                <div className="text-center">
                    {<img style={{ maxWidth: "50px" }} src={icon ? icon : notFoundIcon} alt="" title="" />}
                    <h4 className="card-title text-center">{primaryText}</h4>
                    <p className="mb-0 text-center">{secondaryText}</p>
                </div>
            </Card>
        </div>
    );
};

export default Empty;
