/* eslint-disable no-empty-pattern */
import { useState } from "react";
import axios from "axios";
import { Alert, Card, Input, Button } from "../../Library/Module";
import { toast } from "react-toastify";
import { NavLink, useNavigate } from "react-router-dom";
import Wrapper from "../Wrapper";
import { getTemplatedMessageInput, sendMessage, UserById } from "../../servies/services";

type formDataProps = {
  email_id: string;
  password: string;
};

const Login = () => {
  const navigate = useNavigate();
  const [isFormSubmitted, setIsFormSubmitted]: any = useState(false);
  const [isValid, setIsValid]: any = useState(true);
  const [formData, setFormData] = useState<formDataProps>();

  const onChangeSingleCallback = (data: any) => {
    setFormData((prevState) => ({
      ...prevState,
      ...data,
    }));
    setIsFormSubmitted(false);
  };

  const Submit = async (event: any) => {
    // var data = getTemplatedMessageInput('919665330294', 'Balaji');
    // sendMessage(data)
    //   .then(function (response) {
    //     console.log("response", response)
    //     return;
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //     return;
    //   });

    setIsFormSubmitted(true);
    if (
      formData?.email_id !== undefined &&
      formData?.email_id.length !== 0 &&
      formData?.password !== undefined &&
      formData?.password.length !== 0
    ) {
      setIsValid(true);
      const postData = {
        email_id: formData?.email_id,
        password: formData?.password,
        user_type: "customer",
      };
      await axios
        .post(`https://pawzwhiskers.com/nodeapi/api/v1/user/login`, postData)
        .then((response) => {
          console.log("response", response.data?.id);
          localStorage.setItem('frontendUserData', JSON.stringify(response.data))
          getUserDetails(response.data?.id)

        })
        .catch(function (error) {
          console.log(error.response?.data?.messages)
          if (error.response?.data?.messages) {
            toast.error(error.response?.data?.messages);
          } else {
            toast.error("Something went wrong, please try again");
          }


          console.log(error);
        });
    } else {
      setIsValid(false);
      toast.error("Please enter required fields");
    }
  };

  const getUserDetails = (id: any) => {
    const getData = async () => {
      const APIResponse = await UserById(id);
      if (APIResponse?.data?.isSuccess === true) {
        navigate("/");
        toast.success("You are successfully logged in.");
        localStorage.setItem('frontendUserProfile', JSON.stringify(APIResponse.data?.data))
      } else {
        toast.error("Something went wrong, please try again");


      }
    };
    getData();
  }

  return (
    <Wrapper>
      <div className="login_admin">
        <Card title="">
          <h3>Welcome Back !</h3>
          <p className="text-center mb-4">
            Sign in to continue to Pawzwhiskers Admin Panel
          </p>
          <div className="container-fluid">

            {!isValid && (
              <Alert
                AlertStyleType="danger"
                AlertText={`Please enter required fields`}
              ></Alert>
            )}
            <div className="row">
              <Input
                inputName="email_id"
                inputType="text"
                labelName={"Email Id"}
                placeholder="Enter email id"
                required={true}
                onChangeSingleCallback={onChangeSingleCallback}
                col="12"
                isFormSubmitted={isFormSubmitted}
              />
              <Input
                inputName="password"
                inputType="password"
                labelName={"Password"}
                placeholder="Enter password"
                required={true}
                onChangeSingleCallback={onChangeSingleCallback}
                col="12"
                isFormSubmitted={isFormSubmitted}
              />
            </div>
            <div className="row align-content-end justify-content-end">
              <div className="col-sm-6 text-left">
                <NavLink to="/forgot-password">Forgot Password</NavLink>
              </div>
              <div className="col-sm-6 text-right">
                <Button
                  onClick={() => Submit("")}
                  buttonText={"Login"}
                  buttonStyleType={"primary"}
                />
              </div>
            </div>
          </div>
        </Card>
      </div>
    </Wrapper>
  );
};
export default Login;
