/* eslint-disable no-empty-pattern */
import Slider from "../../components/Slider";
import Services from "../../components/Services";
import AboutUs from "../../components/AboutUs";
import Stats from "../../components/Stats";
import Wrapper from "../Wrapper";
import { useCallback, useState } from "react";
import { formDataProps } from "../../servies/CommonTypes";

const HomePage = () => {
  const [formData, setFormData] = useState<formDataProps>();
  const [serviceList, setServiceList] = useState([])
  const [loadingServiceList, setLoadingServiceList] = useState([])
  const formDataCallback = (data: any) => {
    setFormData((prevState) => ({
      ...prevState,
      ...data,
    }));
  };
  const subCategoryCallback = useCallback((data) => {
    setServiceList(data?.data)
    setLoadingServiceList(data?.data)
  }, [])

  return (
    <Wrapper
      formDataCallback={formDataCallback}
      subCategoryCallback={subCategoryCallback}
    // cityCallback={cityCallback}
    // subCategoryCallback={subCategoryCallback}
    >
      <Slider
      ></Slider>
      <Services
        serviceList={serviceList}
        loadingServiceList={loadingServiceList}></Services>
      <AboutUs></AboutUs>
    </Wrapper>
  );
};
export default HomePage;
