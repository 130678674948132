import React, { createContext, useState, FC, useEffect } from "react";
import { ThemeContextState } from "./types";

const localStorageData = localStorage.getItem('theme')
const contextDefaultValues: ThemeContextState = {
    // @ts-ignore
    theme: JSON.parse(localStorageData),
    addTodo: () => { }
};

export const ThemeContext = createContext<ThemeContextState>(
    contextDefaultValues
);

const TodosProvider: FC = ({ children }) => {
    const [theme, setTheme] = useState<{}>(contextDefaultValues);
    const addTodo = (newTodo: any) => {
        const localStorageDataLocalState = localStorage.getItem('theme')
        
        
    };
console.log("theme", theme)
    useEffect(() => {
        localStorage.setItem('theme', JSON.stringify({ theme }))
    }, [theme])


    return (
        <ThemeContext.Provider
            value={{
                theme,
                addTodo
            }}
        >
            {children}
        </ThemeContext.Provider>
    );
};

export default TodosProvider;
