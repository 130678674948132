import axios from 'axios';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { createBrowserHistory } from "history";
import { cacheAdapterEnhancer } from 'axios-extensions';

const token = localStorage.getItem('admin_token');
const historyConfig = {
    basename: 'tatva-admin/'
};

export const history = createBrowserHistory(historyConfig);

export let site_data = {
    "site_name": "CMS",
    "site_title": "",
    "welcome_back": "Welcome Back !",
    "login_note": "Sign in to continue to Admin Panel"

    //  {site_data.site_name} 
}

export const API = axios.create({
    baseURL: `/`,
    headers: { 'Cache-Control': 'no-cache' },
    // disable the default cache and set the cache flag
    adapter: cacheAdapterEnhancer(axios.defaults.adapter, { enabledByDefault: false, cacheFlag: 'useCache' })

});




export const setUpAxios = async () => {
    return (
        API.interceptors.response.use(response => {
            console.log("response", response)
            return response;
        }, error => {
            console.log("error", JSON.stringify(error));

            if (!error.response) {
                alert("Server is not running")
                console.log("Interceptor - Server is not running");

            } else if (error.response.status === 401) {

                console.log("Interceptor - 401 - Unauthorized: Token Invalid, please login again");

            } else if (error.response.status === 400) {

                console.log("Interceptor - 400" + error.response.data.messages);
                return error.response;

            } else if (error.response.status === 404) {
                alert("API not Found")
                console.log("API not Found");
                return null;

            } else if (error.response.status === 503) {
                alert("Something went wrong, please try again")
                console.log("Interceptor - 503");

            } else {

                return Promise.reject(error);

            }


        })
    )

}

/***** Login ******/

export const authenticate = async (data) => {
    const response = await API.post(process.env.react_app_base_url + '/api/v1/user/login', data)
    return response;
};


// Custom Post Type
export const CreateCustomType = async (data) => {
    const response = await API.post(process.env.react_app_base_url + '/api/v1/cms-type', data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const CustomTypeById = async (id, useCache) => {
    console.log("service CustomTypeById", id)
    const response = await API.get(process.env.react_app_base_url + '/api/v1/cms-type/' + id, {

        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const CustomTypeBySlug = async (slug, useCache) => {
    console.log("service CustomTypeById", slug)
    const response = await API.get(process.env.react_app_base_url + '/api/v1/cms-type/by-slug/' + slug, {

        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const GetCustomPostType = async (data, useCache) => {

    const response = await API.get(process.env.react_app_base_url + '/api/v1/cms-type', {

        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const UpdateCustomPostType = async (data) => {
    console.log("service UpdateCustomPostType", data)
    const response = await API.patch(process.env.react_app_base_url + '/api/v1/cms-type', data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const DeleteCustomPostType = async (id) => {
    console.log("service DeleteCustomPostType", id)
    const response = await API.delete(process.env.react_app_base_url + '/api/v1/cms-type/' + id, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};


// Category
export const CreateCategory = async (data) => {
    console.log("service CreateCategory", data)
    const response = await API.post(process.env.react_app_base_url + '/api/v1/cms-category', data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const CategoryById = async (id, useCache) => {
    console.log("service CategoryById", id)
    const response = await API.get(process.env.react_app_base_url + '/api/v1/cms-category/' + id, {

        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const CategoryBySlug = async (slug, useCache) => {
    console.log("service CategoryBySlug", slug)
    const response = await API.get(process.env.react_app_base_url + '/api/v1/cms-category/by-slug/' + slug, {

        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const GetCategory = async (data, useCache) => {
    console.log("service GetCategory", data)
    const response = await API.get(process.env.react_app_base_url + '/api/v1/cms-category', {

        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const UpdateCategory = async (data) => {
    console.log("service UpdateCategory", data)
    const response = await API.patch(process.env.react_app_base_url + '/api/v1/cms-category', data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const DeleteCategory = async (id) => {
    console.log("service DeleteCategory", id)
    const response = await API.delete(process.env.react_app_base_url + '/api/v1/cms-category/' + id, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const CategoryByCMSType = async (slug, useCache) => {
    console.log("service CategoryByCMSType", slug)
    const response = await API.get(process.env.react_app_base_url + '/api/v1/cms-category/by-cms-type/' + slug, {

        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};


// Fields
export const CreateField = async (data) => {
    console.log("service CreateField", data)
    const response = await API.post(process.env.react_app_base_url + '/api/v1/cms-fields', data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const FieldById = async (id, useCache) => {
    console.log("service FieldById", id)
    const response = await API.get(process.env.react_app_base_url + '/api/v1/cms-fields/' + id, {

        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const FieldBySlug = async (slug, useCache) => {
    console.log("service FieldBySlug", slug)
    const response = await API.get(process.env.react_app_base_url + '/api/v1/cms-fields/by-slug/' + slug, {

        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const GetField = async (data, useCache) => {
    console.log("service GetField", data)
    const response = await API.get(process.env.react_app_base_url + '/api/v1/cms-fields', {

        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const UpdateField = async (data) => {
    console.log("service UpdateField", data)
    const response = await API.patch(process.env.react_app_base_url + '/api/v1/cms-fields', data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const DeleteField = async (id) => {
    console.log("service DeleteField", id)
    const response = await API.delete(process.env.react_app_base_url + '/api/v1/cms-fields/' + id, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};


export const FieldsByCMSType = async (slug, useCache) => {
    console.log("service CategoryByCMSType", slug)
    const response = await API.get(process.env.react_app_base_url + '/api/v1/cms-fields/by-type/' + slug, {

        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};


// Media
export const CreateMedia = async (data) => {
    console.log("service CreateField", data)
    const response = await API.post(process.env.react_app_base_url + '/api/v1/media', data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const MediaById = async (id, useCache) => {
    console.log("service FieldById", id)
    const response = await API.get(process.env.react_app_base_url + '/api/v1/media/' + id, {

        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const MediaBySlug = async (slug, useCache) => {
    console.log("service FieldBySlug", slug)
    const response = await API.get(process.env.react_app_base_url + '/api/v1/media/by-slug/' + slug, {

        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const GetMedia = async (data, useCache) => {
    console.log("service GetField", data)
    const response = await API.get(process.env.react_app_base_url + '/api/v1/media', {

        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const UpdateMedia = async (data) => {
    console.log("service UpdateField", data)
    const response = await API.patch(process.env.react_app_base_url + '/api/v1/media', data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const DeleteMedia = async (id) => {
    console.log("service DeleteField", id)
    const response = await API.delete(process.env.react_app_base_url + '/api/v1/media/' + id, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};


// CMS
export const CreateCMS = async (data) => {
    console.log("service CreateCMS", data)
    const response = await API.post(process.env.react_app_base_url + '/api/v1/cms', data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const CMSById = async (id, useCache) => {
    console.log("service FieldById", id)
    const response = await API.get(process.env.react_app_base_url + '/api/v1/cms/' + id, {

        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const CMSBySlug = async (slug, useCache) => {
    console.log("service CMSBySlug", slug)
    const response = await API.get(process.env.react_app_base_url + '/api/v1/cms/by-slug/' + slug, {

        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const CMSByType = async (slug, useCache) => {
    console.log("service CMSBySlug", slug)
    const response = await API.get(process.env.react_app_base_url + '/api/v1/cms/by-type/' + slug, {

        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const GetCMS = async (data, useCache) => {
    console.log("service GetCMS", data)
    const response = await API.get(process.env.react_app_base_url + '/api/v1/cms', {

        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const UpdateCMS = async (data) => {
    console.log("service UpdateField", data)
    const response = await API.patch(process.env.react_app_base_url + '/api/v1/cms', data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const DeleteCMS = async (id) => {
    console.log("service DeleteField", id)
    const response = await API.delete(process.env.react_app_base_url + '/api/v1/cms/' + id, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};


// User Type
export const CreateUserType = async (data) => {
    const response = await API.post(process.env.react_app_base_url + '/api/v1/user-type', data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const UserTypeById = async (id, useCache) => {
    console.log("service UserTypeById", id)
    const response = await API.get(process.env.react_app_base_url + '/api/v1/user-type/' + id, {

        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const UserTypeBySlug = async (slug, useCache) => {
    console.log("service UserTypeBySlug", slug)
    const response = await API.get(process.env.react_app_base_url + '/api/v1/user-type/by-slug/' + slug, {

        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const GetUserType = async (data, useCache) => {
    console.log("service GetUserType", data)
    const response = await API.get(process.env.react_app_base_url + '/api/v1/user-type', {

        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const UpdateUserType = async (data) => {
    console.log("service UpdateUserType", data)
    const response = await API.patch(process.env.react_app_base_url + '/api/v1/user-type', data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const DeleteUserType = async (id) => {
    console.log("service DeleteUserType", id)
    const response = await API.delete(process.env.react_app_base_url + '/api/v1/user-type/' + id, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

// User
export const RegisterUser = async (data) => {
    const response = await API.post(process.env.react_app_base_url + '/api/v1/user/register', data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const RegisterUserUpdate = async (data) => {
    const response = await API.patch(process.env.react_app_base_url + '/api/v1/user/register', data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const UserById = async (id, useCache) => {
    console.log("service UsersById", id)
    const response = await API.get(process.env.react_app_base_url + '/api/v1/user/users/' + id, {

        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const GetUsers = async (data, useCache) => {
    console.log("service GetUserType", data)
    const response = await API.get(process.env.react_app_base_url + '/api/v1/user/users', {

        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const UpdateUser = async (data) => {
    console.log("service UpdateUser", data)
    const response = await API.patch(process.env.react_app_base_url + '/api/v1/user-profile', data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};


export const CreateProfile = async (data) => {
    console.log("service CreateProfile", data)
    const response = await API.post(process.env.react_app_base_url + '/api/v1/user-profile', data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const UserProfile = async (data) => {
    console.log("service UserProfile", data)
    const response = await API.patch(process.env.react_app_base_url + '/api/v1/user-profile', data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const GetUserDetailsById = async (id, useCache) => {
    console.log("service GetUserDetailsById", id)
    const response = await API.get(process.env.react_app_base_url + '/api/v1/user-profile/' + id, {

        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const DeleteUserInfo = async (data) => {
    console.log("service DeleteUserInfo", data)
    const response = await API.delete(process.env.react_app_base_url + '/api/v1/user-profile/' + data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const DeleteUser = async (data) => {
    console.log("service DeleteUser", data)
    const response = await API.delete(process.env.react_app_base_url + '/api/v1/user/delete-user/' + data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

// submission


export const GetSubmissionByUserId = async (id, useCache) => {
    console.log("service CustomTypeById", id)
    const response = await API.get(process.env.react_app_base_url + '/api/v1/submission/by-user/' + id, {

        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};


export const SubmissionDetails = async (data, useCache) => {
    console.log("service CustomTypeById", data)
    const response = await API.post(process.env.react_app_base_url + '/api/v1/submission', data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};


// Sub Category
export const CreateSubCategory = async (data) => {
    console.log("service CreateCategory", data)
    const response = await API.post(process.env.react_app_base_url + '/api/v1/cms-sub-category', data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const CategorySubById = async (id, useCache) => {
    console.log("service CategoryById", id)
    const response = await API.get(process.env.react_app_base_url + '/api/v1/cms-sub-category/' + id, {

        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const CategorySubBySlug = async (slug, useCache) => {
    console.log("service CategoryBySlug", slug)
    const response = await API.get(process.env.react_app_base_url + '/api/v1/cms-sub-category/by-slug/' + slug, {

        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const GetSubCategory = async (data, useCache) => {
    console.log("service GetSubCategory", data)
    const response = await API.get(process.env.react_app_base_url + '/api/v1/cms-sub-category', {

        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};


export const UpdateSubCategory = async (data) => {
    console.log("service UpdateCategory", data)
    const response = await API.patch(process.env.react_app_base_url + '/api/v1/cms-sub-category', data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const DeleteSubCategory = async (id) => {
    console.log("service DeleteCategory", id)
    const response = await API.delete(process.env.react_app_base_url + '/api/v1/cms-sub-category/' + id, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const CategorySubByCMSType = async (slug, useCache) => {
    console.log("service CategoryByCMSType", slug)
    const response = await API.get(process.env.react_app_base_url + '/api/v1/cms-sub-category/by-cms-type/' + slug, {

        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};


export const CategoryBySubCategory = async (slug, useCache) => {
    console.log("CategoryBySubCategory", slug)
    const response = await API.get(process.env.react_app_base_url + '/api/v1/cms-sub-category/by-cms-sub-catgory/' + slug, {

        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};



// City Management

export const AddCityService = async (data) => {
    console.log("AddCityService", data)
    const response = await API.post(process.env.react_app_base_url + '/api/v1/city', data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    console.log("AddCityService response", response)
    return response;
};

export const DeleteCityService = async (id) => {
    console.log("service DeleteCustomPostType", id)
    const response = await API.delete(process.env.react_app_base_url + '/api/v1/city/' + id, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const GetCityService = async () => {
    const response = await API.get(process.env.react_app_base_url + '/api/v1/city', {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const GetCityDetaildByIdService = async (id) => {
    console.log("service GetCityDetaildByIdService", id)
    const response = await API.get(process.env.react_app_base_url + '/api/v1/city/' + id, {

        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const UpdateCityDetaildByIdService = async (data) => {
    console.log("service UpdateCityDetaildByIdService", data)
    const response = await API.patch(process.env.react_app_base_url + '/api/v1/city', data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};


// Area Management
export const AddAreaService = async (data) => {
    console.log("AddCityService", data)
    const response = await API.post(process.env.react_app_base_url + '/api/v1/area', data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const GetAreaService = async () => {
    const response = await API.get(process.env.react_app_base_url + '/api/v1/area', {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const DeleteCityAreaService = async (id) => {
    console.log("service DeleteCustomPostType", id)
    const response = await API.delete(process.env.react_app_base_url + '/api/v1/area/' + id, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};



// Pets
export const AddPetsService = async (data) => {
    console.log("AddCityService", data)
    const response = await API.post(process.env.react_app_base_url + '/api/v1/pets', data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    console.log("AddCityService response", response)
    return response;
};

export const DeletePetsService = async (id) => {
    console.log("service DeleteCustomPostType", id)
    const response = await API.delete(process.env.react_app_base_url + '/api/v1/pets/' + id, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const GetPetsService = async () => {
    const response = await API.get(process.env.react_app_base_url + '/api/v1/pets', {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const GetPetsDetaildByIdService = async (id) => {
    console.log("service GetCityDetaildByIdService", id)
    const response = await API.get(process.env.react_app_base_url + '/api/v1/pets/' + id, {

        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const UpdatePetsDetaildByIdService = async (data) => {
    console.log("service UpdateCityDetaildByIdService", data)
    const response = await API.patch(process.env.react_app_base_url + '/api/v1/pets', data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};



// Vendor 

export const Vendors = async (data, useCache) => {
    console.log("service GetSubCategory", data)
    const response = await API.get(process.env.react_app_base_url + '/api/v1/vendor' + data, {

        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};
// vendor-count/:category
export const VendorCatCount = async (query, data) => {
    console.log("service VendorCatCount", query, data)
    const response = await API.post(process.env.react_app_base_url + `/api/v1/vendor/v2/count?${query}`, data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const AddVendor = async (data, useCache) => {
    console.log("service GetSubCategory", data)
    const response = await API.post(process.env.react_app_base_url + '/api/v1/vendor', data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const SubCategoryByCategorySlug = async (slug, useCache) => {
    console.log("CategoryBySubCategory", slug)
    const response = await API.get(process.env.react_app_base_url + '/api/v1/cms-sub-category/by-cms-sub-catgory/' + slug, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};


export const GetCityAreaService = async (city) => {
    const response = await API.get(process.env.react_app_base_url + '/api/v1/area/by-city/' + city, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};



// Vendor
export const AddVendorService = async (data) => {
    console.log("AddCityService", data)
    const response = await API.post(process.env.react_app_base_url + '/api/v1/vendor', data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    console.log("AddCityService response", response)
    return response;
};

export const GetVendorService = async () => {
    const response = await API.get(process.env.react_app_base_url + '/api/v1/vendor', {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const DeleteVendorService = async (id) => {
    console.log("service DeleteCustomPostType", id)
    const response = await API.delete(process.env.react_app_base_url + '/api/v1/vendor/' + id, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const GetVendorServiceById = async (id) => {
    console.log("service GetCityDetaildByIdService", id)
    const response = await API.get(process.env.react_app_base_url + '/api/v1/vendor/' + id, {

        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const UpdateVendorServiceById = async (data) => {
    console.log("service UpdateCityDetaildByIdService", data)
    const response = await API.patch(process.env.react_app_base_url + '/api/v1/vendor', data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const EnquiryDetails = async (data, useCache) => {
    console.log("service CustomTypeById", data)
    const response = await API.post(process.env.react_app_base_url + '/api/v1/user/equiry', data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};
// 
export const InsuranceEquiry = async (data, useCache) => {
    console.log("service CustomTypeById", data)
    const response = await API.post(process.env.react_app_base_url + '/api/v1/user/insurance_equiry', data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};
export const getTemplatedMessageInput = (recipient, user) => {
    return JSON.stringify({
        "messaging_product": "whatsapp",
        "to": recipient,
        "type": "template",
        "template": {
            "name": "account_information",
            "language": {
                "code": "en"
            },
            "components": [
                {
                    "type": "header",
                    "parameters": [
                        {
                            "type": "text",
                            "text": `${user}`,

                        }
                    ]
                },

            ]
        }
    }
    );
}

export const sendMessage = (data) => {
    var config = {
        method: 'post',
        // url: `https://graph.facebook.com/${process.env.VERSION}/${process.env.PHONE_NUMBER_ID}/messages`,
        url: `https://graph.facebook.com/v13.0/107278118975283/messages`,
        headers: {
            // 'Authorization': `Bearer ${process.env.ACCESS_TOKEN}`,
            'Authorization': `Bearer EAAMn0mV3ymsBAKa7UxqUFK5ZCC0ugZACz0WjQSCgIbZAneE9ZAzkuBAFLdaT6VN9zjAwuxu8WRiy9TbROWBvsXHt3jTWhBJhIws1ZBVC71KvfHJ9zGzzbp0NxXJfNQYxDMBTjOsnZC777ZAVrvuBMQrQoB81vHd7W62ZC2ErZBWKBdKqS4eQgXFZBl2ZCZCN8VZCJzZAYowO78oE8NEQVRQkHkkKZAspp5Xri3MZCQcZD`,
            'Content-Type': 'application/json'
        },
        data: data
    };

    return axios(config)
}

export const getTextMessageInput = (recipient, text) => {
    return JSON.stringify({
        "messaging_product": "whatsapp",
        "preview_url": true,
        "recipient_type": "individual",
        "to": recipient,
        "type": "text",
        "text": {
            "body": text
        }
    });
}