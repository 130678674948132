import React, { createContext, useReducer, Dispatch } from 'react'

export const localStorageParse = (key: string) => {
  try {
    return localStorage.getItem(key)
  } catch (error) {
    return null
  }

}

export const sessionStorageParse = (key: string) => {
  try {
    return sessionStorage.getItem(key)
  } catch (error) {
    return null
  }

}

type authenticationType = {

}
type PreferencesType = {

}
export type ThemeType =
  {
    auth: { type: 'UPDATE_AUTH'; payload: { authentication: any } },
    pref: { type: 'UPDATE_PREF'; payload: { Preferences: any } }
  }

export const defaultAuthState: authenticationType = {
  "authenticate": false,
  "token": "",
  "user_details": "",

}
export const defaultPreferencesState: PreferencesType = {
  "pet": "",
  "category": "",
  "city": "",
  "area": ""
}

const initialState = () => {
  return {
    auth: localStorageParse('auth') ?? { ...defaultAuthState },
    pref: localStorageParse('pref') ?? { ...defaultPreferencesState }
  }
}

const ThemeContext = createContext<{
  state: any
  dispatch: Dispatch<any>
}>({
  state: initialState,
  dispatch: () => null
})

const { Provider } = ThemeContext

const reducer = (state: any, action: any): any => {
  switch (action.type) {
    case 'UPDATE_AUTH':
      localStorage.setItem('auth', JSON.stringify({
        ...state,
        ...action.payload.auth
      }))
      return {
        ...state,
        ...action.payload.auth
      }

    case 'UPDATE_PREF':
      localStorage.setItem('auth', JSON.stringify({
        ...state,
        ...action.payload.pref
      }))
      return {
        ...state,
        ...action.payload.pref
      }
    default:
      return { ...state }
  }
}

const ThemeProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState())
  return <Provider value={{ state, dispatch }}>{children}</Provider>
}


export  { ThemeProvider, ThemeContext, reducer }