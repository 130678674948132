/* eslint-disable no-empty-pattern */
import { useEffect, useState } from "react";
import axios from "axios";
import {
  Alert,
  Card,
  Input,
  Button,
  Toggle,
  Select,
} from "../../Library/Module";
import { toast } from "react-toastify";
import { NavLink, useNavigate } from "react-router-dom";
import Wrapper from "../Wrapper";
import { RegisterUser, AddVendor, GetCityAreaService } from '../../servies/services'
import { Age, PetOptions } from "../../Library/Utility/Utility";
import { localStorageParse } from "../../Context/ThemeContext";

type formDataProps = {
  email_id: string;
  password: string;
  dog?: boolean;
  foster_parent?: boolean;
  first_name: string;
  last_name: string;
  mobile: string;
  status: string;
  dog_breed?: string
  age?: string
  title?: string
  experience_with_dogs?: string
  dog_size_preference?: string
  area?: string
  pet?: string
};

const Register = () => {
  const navigate = useNavigate();
  const [isError, setIsError]: any = useState(false);
  const [isFormSubmitted, setIsFormSubmitted]: any = useState(false);
  const [isValid, setIsValid]: any = useState(true);
  const [formData, setFormData] = useState<formDataProps>();
  const [areaList, setAreaList] = useState([]);

  const onChangeSingleCallback = (data: any) => {
    setFormData((prevState) => ({
      ...prevState,
      ...data,
    }));
    setIsFormSubmitted(false);
  };


  const city_prefernce = localStorageParse('city_prefernce')
  const city_parse = city_prefernce && JSON.parse(city_prefernce)
  const cityName = city_parse?.city
  console.log("city_parse", city_parse?.city)

  useEffect(() => {

    const getData = async () => {
      const APIResponse = await GetCityAreaService(cityName);
      if (APIResponse?.data?.isSuccess === true) {
        console.log("APIResponse CategoryByCMSType", APIResponse?.data?.data)
        let updatedList: any = []
        APIResponse?.data?.data?.map((item: any) => {
          return updatedList.push({ text: item?.title, id: item?._id, value: item?.slug })
        })
        setAreaList(updatedList);
      } else {
        toast.error("Something went wrong, please try again");

      }
    };
    getData();

  }, [cityName])

  const Submit = async (event: any) => {
    setIsFormSubmitted(true);
    if (
      formData?.email_id !== undefined &&
      formData?.email_id.length !== 0 &&
      formData?.password !== undefined &&
      formData?.password.length !== 0
    ) {
      setIsValid(true);

      const details = {
        "dog": formData?.dog,
        "dog_breed": formData?.dog_breed,
        "age": formData?.age,
        "foster_parent": formData?.foster_parent,
        "title": formData?.title,
        "experience_with_dogs": formData?.experience_with_dogs,
        "area": formData?.area,
        "dog_size_preference": formData?.dog_size_preference,
        "pet": formData?.pet
      }

      const postData = {
        email_id: formData?.email_id.toLowerCase(),
        password: formData?.password,
        user_type: 'customer',
        first_name: formData?.first_name,
        last_name: formData?.last_name,
        mobile: formData?.mobile ?? '*****',
        status: "0",
        details: details
      };

      const APIResponse = await RegisterUser(postData);
      if (APIResponse?.data?.isSuccess === true) {
        // toast.success(APIResponse.data.messages);
        if (formData?.foster_parent === false) {
          console.log("APIResponse.data", APIResponse.data)
          getData(formData);
        } else {
          navigate("/login")
        }
      } else {
        if (APIResponse?.data?.message) {
          toast.error(APIResponse?.data?.message);
        } else {
          toast.error("Something went wrong, please try again");
        }
      }


    } else {
      setIsValid(false);
      toast.error("Please enter required fields");
    }
  };

  const ForgotPassword = () => {
    navigate("/forgot-password");
  };

  const getData = async (userData: any) => {
    const data = {
      "title": userData?.title,
      "first_name": userData?.first_name,
      "last_name": userData?.last_name,
      "email_id": userData?.email_id,
      "mobile": userData?.mobile,
      "address": userData?.address,
      "city": 'pune',
      "area": userData?.area,
      "about_vendor": null,
      "payment_mode": 'Online & Offline',
      "min_charges": "0",
      "establish_year": userData.experience_with_dogs,
      "opening_hours": " ",
      "pet_category": "dog",
      "service_category": "dog-foster-homes",
      "status": "Active",
      "profile_image": null
    }
    const APIResponse = await AddVendor(data);
    if (APIResponse?.data?.isSuccess === true) {
      console.log("APIResponse.data?.data", APIResponse.data?.data)
      navigate("/");
      toast.success("You have Successfully Registered.");
      localStorage.setItem('frontendUserProfile', JSON.stringify(APIResponse.data?.data))
    } else {
      toast.error("Something went wrong, please try again");
      setIsError(true);

    }
  };



  return (
    <Wrapper>
      <div className="login_admin" style={{ maxWidth: "650px" }}>
        <Card title="">
          <h3>Welcome Back !</h3>
          <p className="text-center mb-4">
            Sign in to continue to Pawzwhiskers Admin Panel
          </p>
          <div className="container-fluid">
            {isError && (
              <Alert
                AlertStyleType="danger"
                AlertText={`some error msg here`}
              ></Alert>
            )}
            {!isValid && (
              <Alert
                AlertStyleType="danger"
                AlertText={`Please enter required fields`}
              ></Alert>
            )}
            <div className="row">
              <Input
                inputName="first_name"
                inputType="text"
                labelName={"First Name"}
                placeholder="Enter First Name"
                required={true}
                onChangeSingleCallback={onChangeSingleCallback}
                col="6"
                isFormSubmitted={isFormSubmitted}
              />
              <Input
                inputName="last_name"
                inputType="text"
                labelName={"Last Name"}
                placeholder="Enter Last Name"
                required={true}
                onChangeSingleCallback={onChangeSingleCallback}
                col="6"
                isFormSubmitted={isFormSubmitted}
              />
              {/* <Input
                inputName="mobile"
                inputType="text"
                labelName={"Mobile"}
                placeholder="Enter Mobile"
                required={true}
                onChangeSingleCallback={onChangeSingleCallback}
                col="6"
                isFormSubmitted={isFormSubmitted}
              /> */}
              <Input
                inputName="email_id"
                inputType="text"
                labelName={"Email Id"}
                placeholder="Enter Email Id"
                required={true}
                onChangeSingleCallback={onChangeSingleCallback}
                col="6"
                isFormSubmitted={isFormSubmitted}
              />
              <Input
                inputName="password"
                inputType="password"
                labelName={"Password"}
                placeholder="Enter Password"
                required={true}
                onChangeSingleCallback={onChangeSingleCallback}
                col="6"
                isFormSubmitted={isFormSubmitted}
              />

              <Select
                inputName="pet"
                labelName="Pet"
                options={PetOptions}
                onChangeSingleCallback={onChangeSingleCallback}
                required={true}
                placeholder="Select Pet"
                search_option={false}
                col="6"
              ></Select>

              {/* <Input
                inputName="address"
                inputType="text"
                labelName={"Address"}
                placeholder="Enter Address"
                required={true}
                onChangeSingleCallback={onChangeSingleCallback}
                col="6"
                isFormSubmitted={isFormSubmitted}
              /> */}
              {/* <Toggle
                required={true}
                col="12"
                onChangeSingleCallback={onChangeSingleCallback}
                inputName={"dog"}
                labelName="Dog"
              ></Toggle>
              {formData?.dog !== undefined && formData?.dog === false && (
                <>
                  <Input
                    inputName="dog_breed"
                    inputType="text"
                    labelName={"Breed"}
                    placeholder="Enter Dog Breed"
                    required={true}
                    onChangeSingleCallback={onChangeSingleCallback}
                    col="6"
                    isFormSubmitted={isFormSubmitted}
                  />
                  <Select
                      inputName="age"
                      labelName="Age"
                      options={Age}
                      onChangeSingleCallback={onChangeSingleCallback}
                      required={true}
                      placeholder="Select Dog Age"
                      search_option={false}
                      col="6"
                    ></Select>
                  {/* <Input
                    inputName="age"
                    inputType="number"
                    labelName={"Age"}
                    placeholder="Enter Age"
                    min={0}
                    minLength={0}
                    required={true}
                    onChangeSingleCallback={onChangeSingleCallback}
                    col="6"
                    isFormSubmitted={isFormSubmitted}
                  /> 
                </>
              )}
              <Toggle
                required={true}
                col="12"
                onChangeSingleCallback={onChangeSingleCallback}
                inputName={"foster_parent"}
                labelName="Interested in being Dog or Cat Foster Parent"
              ></Toggle>
              {formData?.foster_parent !== undefined &&
                formData?.foster_parent === false && (
                  <>
                    <Input
                      inputName="title"
                      inputType="text"
                      labelName={"Profile Name"}
                      placeholder="Enter Profile Name"
                      required={true}
                      onChangeSingleCallback={onChangeSingleCallback}
                      col="6"
                      isFormSubmitted={isFormSubmitted}
                    />
                    <Select
                      inputName="experience_with_dogs"
                      labelName="Experience with Pets"
                      options={Age}
                      onChangeSingleCallback={onChangeSingleCallback}
                      required={true}
                      placeholder="Select Experience with Pets"
                      search_option={false}
                      col="6"
                    ></Select>
                    <Select
                      inputName="area"
                      labelName="Area"
                      options={areaList ?? []}
                      onChangeSingleCallback={onChangeSingleCallback}
                      required={true}
                      placeholder="Select Area"
                      search_option={false}
                      col="6"
                    ></Select>
                    
                    
                    <Input
                      inputName="dog_size_preference"
                      inputType="number"
                      labelName={"Additional Comments"}
                      placeholder="Additional Comments"
                      required={false}
                      onChangeSingleCallback={onChangeSingleCallback}
                      col="6"
                      isFormSubmitted={isFormSubmitted}
                    />
                   
                  </>
                )} */}
            </div>
            <div className="row align-content-end justify-content-end">
              <div className="col-sm-6 text-left">
                <NavLink
                  onClick={() => ForgotPassword()}
                  to="/forgot-password"
                >
                  Forgot Password
                </NavLink>
              </div>
              <div className="col-sm-6 text-right">
                <Button
                  onClick={() => Submit("")}
                  buttonText={"Register Now"}
                  buttonStyleType={"primary"}
                />
              </div>
            </div>
          </div>
        </Card>
      </div>
    </Wrapper>
  );
};
export default Register;
