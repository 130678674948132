/* eslint-disable no-empty-pattern */
import {
    GenderOption,
    TimeSlotPreference,
} from "../../components/utility";
import { useEffect, useState } from "react";
import { Alert, Button, Input, Select, Toggle } from "../../Library/Module";
import { NavLink } from "react-router-dom";
import DatePicker from '../../Library/DatePicker/DatePicker';
import { localStorageParse } from "../../Context/ThemeContext";

type formDataProps = {
    [key: string]: string;
};

type Props = {
    showResult: any
    pet: any
    category: any
    userLogged: any
    area?: any
    scollToRef?: any
    handleClick?: any

}
const ServicesDetails = ({
    showResult = false,
    pet,
    category,
    userLogged,
    area,
    scollToRef,
    handleClick
}: Props): JSX.Element => {
    const initialData = localStorageParse(pet + "_" + category)
    const [formData, setFormData] = useState<any>(initialData && JSON.parse(initialData));

    const [isValid, setIsValid] = useState(false)
    const onChangeSingleCallback = (data: any) => {
        setFormData((prevState:any) => ({
            ...prevState,
            ...data,
        }));
        showResult(false);
    };
    const Submit = () => {
        showResult(true);
        localStorage.setItem(pet + "_" + category, JSON.stringify(formData))
        if (scollToRef.current) {
            scollToRef.current.scrollIntoView()
        }
        handleClick?.()
    };
    useEffect(() => {
        // {"area":"any","dog_bread":"test","gender":"Male","age":"21","time_slot_preference":"9AM\tto 12AM","comments":"test"}

        if (formData?.dog_bread && formData?.dog_bread?.length > 0 &&
            formData?.gender && formData?.gender?.length > 0 &&
            formData?.age && formData?.age?.length > 0 &&
            formData?.area && formData?.area?.length > 0 &&
            formData?.destination && formData?.destination?.length > 0 &&
            formData?.time_slot_preference && formData?.time_slot_preference?.length > 0) {
            if (userLogged === true) {
                setIsValid(true)
            } else {
                setIsValid(true)
            }

        } else {
            setIsValid(false)
        }

    }, [formData, userLogged])
    return (
        <>
            <div className="row">
                <Input
                    inputName="dog_bread"
                    inputType="text"
                    labelName={"Dog Breed"}
                    placeholder="Enter Dog Breed"
                    required={true}
                    onChangeSingleCallback={onChangeSingleCallback}
                    col="3"
                    value={formData?.dog_bread}
                />
                <Select
                    inputName="gender"
                    labelName="Gender"
                    options={GenderOption ?? []}
                    onChangeSingleCallback={onChangeSingleCallback}
                    required={true}
                    placeholder="Select Gender"
                    search_option={false}
                    col="3"
                    selectedItem={GenderOption?.find((item: any) => {
                        return item.value === formData?.gender;
                      })}
                ></Select>
                <Input
                    inputName="age"
                    inputType="number"
                    labelName={"Age"}
                    placeholder="Enter Age"
                    required={true}
                    onChangeSingleCallback={onChangeSingleCallback}
                    col="3"
                    value={formData?.age}
                />
                <Select
                    inputName="area"
                    labelName="Area"
                    options={area ?? []}
                    onChangeSingleCallback={onChangeSingleCallback}
                    required={true}
                    placeholder="Select Area"
                    search_option={false}
                    col="3"
                    selectedItem={area?.find((item: any) => {
                        return item.value === formData?.area;
                      })}
                ></Select>
                <DatePicker
                    inputName="appointment_date"
                    labelName={"Appointment Date"}
                    placeholder="Enter Appointment Date"
                    required={true}
                    onChangeSingleCallback={onChangeSingleCallback}
                    col="3"></DatePicker>
                <Select
                    inputName="time_slot_preference"
                    labelName="Time	slot preference"
                    options={TimeSlotPreference ?? []}
                    onChangeSingleCallback={onChangeSingleCallback}
                    required={true}
                    placeholder="Select Time slot preference"
                    search_option={false}
                    col="3"
                    selectedItem={TimeSlotPreference?.find((item: any) => {
                        return item.value === formData?.time_slot_preference;
                      })}
                      
                ></Select>
                <Input
                    inputName="destination"
                    labelName={"Destination"}
                    placeholder="Enter Destination"
                    required={true}
                    onChangeSingleCallback={onChangeSingleCallback}
                    col="3"
                    value={formData?.destination}
                />
            </div>
            <div className="row align-items-center">
                <div className="col-sm-2">
                    
                        <Button
                            onClick={() => Submit()}
                            buttonText={"Search"}
                            buttonStyleType={"primary"}
                            buttonWidth="sm"
                            disabled={!isValid}
                        />
                  
                </div>
            </div>
        </>
    )
};
export default ServicesDetails;